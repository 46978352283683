<template>
  <div class="soft-cmp">
    <h1>Web development process in a nutshell</h1>
    <p>
      From the ideation stage to maintenance – every project at Vuega fits into a well-defined framework that facilitates delivering high-quality web apps in a relatively short time. We always encourage you to participate in all its stages because we believe close cooperation yields the best results. By working with us, you gain a genuine partner and consultant who will assist you at every step of the web development process, which looks like this.
    </p>
    <div class="section">
      <h2>Product Discovery Workshops</h2>
      <p>
        This is the initial step in our partnership because it helps us figure out and assess your app concept. We focus on acquiring a detailed business perspective on the project to design a precise implementation plan that corresponds with your vision. The project roadmap provides a clear catalog of milestones and due dates our development team needs to follow.
      </p>
    </div>
    <div class="section">
      <h2>UX/UI Design</h2>
      <p>
        Now, we meticulously construct your product’s design to obtain a visually attractive and intuitive User Experience. To begin, we perform market research and create user personas. Based on this, our team generates simple wireframes, which are converted into interactive prototypes. These operations result in an official draft of your web app consistent with your brand.
      </p>
    </div>
    <div class="section">
      <h2>Agile Development & QA</h2>
      <p>
        At this stage, we turn the image into a complete app. In line with Scrum methodology, the team delivers your web app’s components in cycles and checks their efficiency using automated tests. When all the elements are in place, the app goes through a rigorous Quality Assurance procedure to check if it functions correctly. The whole development stage is well-thought-off and supervised by an experienced Scrum Master to deliver your product without any surprises.
      </p>
    </div>
    <div class="section">
      <h2>Release</h2>
      <p>
        Once development and testing are complete, we prep your web application for launch. Yes, your users will finally see the fruit of our collaboration! But before this can happen, our team prepares a server to host the app and configure the settings. Then we conduct a dry-run to confirm that the application can handle the traffic. If that’s the case, we make it available to your customers.
      </p>
    </div>
    <div class="section">
      <h2>Maintenance</h2>
      <p>
        We will not ditch your project once the product has been released. As a full-service website development agency, we also oversee your app’s maintenance, which includes fixing bugs and security issues, as well as upgrading it to the newest technology. Seems too good to be true? Work with us and see for yourself.
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'WebNutshell'
}
</script>

<style scoped>
.soft-cmp {
  background-color: white; /* Background color */
  padding: 20px 20px 20px 0; /* Padding: Top, Right, Bottom, Left */
  border-radius: 8px; /* Rounded corners */
  text-align: start; /* Text alignment */
  margin: 20px auto; /* Center the container and add top/bottom margin */
  max-width: 75%; /* Limit the max width */
  margin-top: 100px;
  position: relative; /* Ensure the pseudo-element is positioned relative to this container */
}

h1 {
  font-family: "Montserrat", sans-serif;
  font-size: 40px !important;
  font-weight: 700 !important;
  line-height: 48px !important;
  margin: 0; /* Remove default margin to eliminate space between headings */
  margin-bottom: 40px;
  position: relative; /* Ensure the pseudo-element is positioned relative to the h1 */
}

h1::after {
  content: "";
  display: block;
  width: 5%; /* Width of the underline */
  height: 5px; /* Height of the underline */
  background-color: #008bff; /* Color of the underline */
  position: absolute; /* Position it at the bottom of the h1 */
  bottom: -10px; /* Align it slightly below the h1 */
  left: 0; /* Start from the left */
}

h2 {
  font-family: "Montserrat", sans-serif;
  font-size: 32px !important;
  font-weight: 6000px;
  line-height: 32px !important;
  margin: 20px 0 10px; /* Space around the subheadings */
  margin-bottom: 40px;
  margin-top: 30px;
}

p {
  font-family: "Montserrat", sans-serif;
  font-size: 16px !important;
  line-height: 24px !important;
  margin: 0 0 20px; /* Space below paragraphs */
}
</style>
