<template>
    <div class="image-container">
      <img src="@/assets/web.svg" alt="Working Image" class="image" />
    </div>
  </template>
  
  <script>
  export default {
    name: 'WebImage',
  }
  </script>
  
  <style scoped>
  .image-container {
    text-align: center; /* Center the image within the container */
  }
  
  .image {
    display: flex;
    width: 560px; /* Adjust size as needed */
    height: 465px; /* Maintain aspect ratio */
  }
  </style>
  