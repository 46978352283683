<template>
  <div class="soft-cmp">
    <h1>
      Not ready for mobile development?
      <br><br>
    </h1>
    <a href="https://docs.google.com/forms/d/e/1FAIpQLSfoKVND-WJkwXQSpqVHsiwwCUQes3HynfRafTpmo-zy9REbug/viewform" target="_blank" rel="noopener noreferrer" class="blue-container-link">
      <div class="blue-container">
        <font-awesome-icon :icon="['fas', 'comment-alt']" />
        <span>Free Consultation</span>
      </div>
    </a>
  </div>
</template>

<script>
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faCommentAlt } from '@fortawesome/free-solid-svg-icons';

library.add(faCommentAlt);

export default {
  name: 'FreeConsultation',
  components: {
    FontAwesomeIcon,
  },
};
</script>

<style scoped>
/* Universal reset for margin and padding */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html, body {
  width: 100%;
  height: 100%;
  overflow-x: hidden; /* Prevent horizontal scrolling */
}

.soft-cmp {
  background-color: #f6fbfe; /* Container background color */
  padding: 0; /* Remove padding */
  margin: 0; /* Remove margin */
  text-align: center; /* Center text horizontally */
  width: 100%; /* Set container width to 100% of the viewport */
  max-width: 100vw; /* Ensure container does not exceed viewport width */
  height: 310px; /* Set container height */
  display: flex; /* Use flexbox for centering */
  flex-direction: column; /* Stack elements vertically */
  align-items: center; /* Center content horizontally */
  justify-content: center; /* Center content vertically */
  margin-top: 100px; /* Top margin for spacing */
}

h1 {
  font-family: "Montserrat", sans-serif;
  font-size: 40px;
  font-weight: 700;
  line-height: 48px;
  color: black; /* Text color */
  margin: 0; /* Remove default margin */
}

.blue-container-link {
  text-decoration: none; /* Remove underline from link */
}

.blue-container {
  font-family: "Kanit", sans-serif;
  font-weight: 400;
  font-style: normal;
  max-width: 1500px;
  max-height: 1000px;
  display: flex;
  align-items: center;
  background-color: #0435f9; /* Blue background color */
  padding: 15px 20px; /* Padding around the text */
  border-radius: 6px; /* Rounded corners */
  color: white; /* Text color inside the blue container */
  font-weight: bold;
  font-size: 24px; /* Smaller font size */
  transition: background-color 0.3s ease; /* Smooth transition for background color */
}

.blue-container svg {
  margin-right: 10px; /* Space between icon and text */
  font-size: 24px; /* Adjust the icon size */
}

.blue-container:hover {
  background-color: #021a7a; /* Change background color to darker blue on hover */
}
</style>
