<template>
  <div class="main-container">
    <div class="top-row">
      <div class="small-container">
        <div class="image-container">
          <img src="@/assets/darshan2_badass.jpg" alt="Darshan R A" />
          <div class="info">
            <div class="social-icons">
              <a href="https://www.linkedin.com/in/darshan-r-a-47b498240/" target="_blank" rel="noopener noreferrer">
                <Icon icon="skill-icons:linkedin" height="24" />
              </a>
              <a href="https://twitter.com/" target="_blank" rel="noopener noreferrer">
                <Icon icon="line-md:twitter-x-alt"  style="color: white" />
              </a>
              <a href="https://wa.me/+919489241506" target="_blank">
                <Icon icon="logos:whatsapp-icon" class="social-icon" />
              </a>
            </div>
          </div>
        </div>
        <div class="text-container">
          <div class="vertical-line" style="background-color: #FF5733;"></div> <!-- Vibrant Orange -->
          <h3>Darshan R A</h3>
          <h4>Founder, CEO Campus Ambassador IIT Bombay</h4>
          <p>Darshan R.A is the Campus Ambassador at E-CELL IIT Bombay with a strong passion for AI and embedded design. His journey has equipped him with solid skills in Python development, and he is dedicated to advancing technology and innovation in these fields. He thrives on exploring new challenges and contributing to impactful projects.</p>
        </div>
      </div>
      <div class="small-container">
        <div class="image-container">
          <img src="@/assets/Praanesh_badass.png" alt="Praanesh S" />
          <div class="info">
            <div class="social-icons">
              <a href="https://www.linkedin.com/in/praanesh-s/" target="_blank" rel="noopener noreferrer">
                <Icon icon="skill-icons:linkedin" height="24" />
              </a>
              <a href="https://twitter.com/" target="_blank" rel="noopener noreferrer">
                <Icon icon="line-md:twitter-x-alt"  style="color: white" />
              </a>
              <a href="https://wa.me/+919629930357" target="_blank">
                <Icon icon="logos:whatsapp-icon" class="social-icon" />
              </a>
            </div>
          </div>
        </div>
        <div class="text-container">
          <div class="vertical-line" style="background-color: #8E44AD;"></div> <!-- Vibrant Purple -->
          <h3>Praanesh S</h3>
          <h4>Founder, CTO</h4>
          <p>Praanesh, CTO of Vuega, excels in Python development and open-source contributions. His enthusiasm for Generative AI and MLOps drives innovation and ensures cutting-edge solutions. His expertise ensures that Vuega remains at the forefront of technological advancements, delivering impactful solutions.</p>
        </div>
      </div>
    </div>
    <div class="bottom-row">
      <div class="small-container">
        <div class="image-container">
          <img src="@/assets/kayal.jpg" alt="Kayal Ennian A G" />
          <div class="info">
            <div class="social-icons">
              <a href="https://www.linkedin.com/in/kayal-ennian-a-g-80b515245/" target="_blank" rel="noopener noreferrer">
                <Icon icon="skill-icons:linkedin" height="24" />
              </a>
              <a href="https://twitter.com/" target="_blank" rel="noopener noreferrer">
                <Icon icon="line-md:twitter-x-alt"  style="color: white" />
              </a>
              <a href="https://wa.me/+919025829115" target="_blank">
                <Icon icon="logos:whatsapp-icon" class="social-icon" />
              </a>
            </div>
          </div>
        </div>
        <div class="text-container">
          <div class="vertical-line" style="background-color: #33FF57;"></div> <!-- Vibrant Green -->
          <h3>Kayal Ennian A G</h3>
          <h4>Founder, COO</h4>
          <p>Kayal, COO of Vuega, excels in UI/UX design and full-stack development. With a deep passion for Flutter and a keen interest in deep learning, Kayal drives innovation and user-centric solutions across all projects.</p>
        </div>
      </div>
      <div class="small-container">
        <div class="image-container">
          <img src="@/assets/Vishnu_profile.jpeg" alt="Vishnu Ram M" />
          <div class="info">
            <div class="social-icons">
              <a href="https://www.linkedin.com/in/vrdev/" target="_blank" rel="noopener noreferrer">
                <Icon icon="skill-icons:linkedin" height="24" />
              </a>
              <a href="https://twitter.com/" target="_blank" rel="noopener noreferrer">
                <Icon icon="line-md:twitter-x-alt"  style="color: white" />
              </a>
              <a href="https://wa.me/+919283200200" target="_blank">
                <Icon icon="logos:whatsapp-icon" class="social-icon" />
              </a>
            </div>
          </div>
        </div>
        <div class="text-container">
          <div class="vertical-line" style="background-color: #E67E22;"></div> <!-- Vibrant Orange -->
          <h3>Vishnu Ram M</h3>
          <h4>Founder, COO</h4>
          <p>Vishnu, COO of Vuega, is a versatile professional skilled in MERN development, UI/UX design, and Python. With a strong focus on digital twins and deep learning, he leads with expertise in both development and strategic operations.</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Icon } from '@iconify/vue';

export default {
  name: 'TeamGrid',
  components: {
    Icon
  }
}
</script>

<style scoped>
.main-container {
  display: flex;
  flex-direction: column;
  width: 75%;
  margin: 0 auto;
  padding: 20px;
}

.top-row, .bottom-row {
  display: flex;
  gap: 20px;
  margin-bottom: 20px;
}

.small-container {
  display: flex;
  flex: 1;
  background-color: white;
  border-radius: 8px;
  margin: 10px;
  position: relative;
}

.image-container {
  position: relative;
  flex: 0 0 216px;
  height: 270px;
  overflow: hidden;
  border-radius: 8px;
}

.image-container img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 8px;
  /* filter: grayscale(100%); */
}

.info {
  position: absolute;
  bottom: -30%;
  left: 0;
  width: 100%;
  padding: 20px;
  background: rgba(0, 0, 0, 0.7);
  color: #fff;
  transition: bottom 0.3s ease;
}

.image-container:hover .info {
  bottom: 0;
}

.social-icons {
  display: flex;
  gap: 15px;
  margin-top: 10px;
}

.social-icons a {
  color: #fff;
  text-decoration: none;
}

.social-icons svg {
  width: 24px;
  height: 24px;
  filter: grayscale(100%);
  transition: filter 0.3s ease-in-out;
}

.social-icons svg:hover {
  filter: grayscale(0%);
}

.text-container {
  flex: 1;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  position: relative;
}

.vertical-line {
  position: absolute;
  left: 30px;
  top: 27px;
  height: 69px;
  width: 3px;
  border-radius: 4px;
}

.text-container h3 {
  font-family: "Montserrat", sans-serif;
  font-size: 24px;
  font-weight: 700;
  margin: 0 0 10px 40px;
}

.text-container h4 {
  font-family: "Montserrat", sans-serif;
  font-size: 18px;
  font-weight: 500;
  margin: 0 0 10px 40px;
}

.text-container p {
  font-family: "Montserrat", sans-serif;
  font-size: 14px;
  line-height: 20px;
  margin: 0 0 0 40px;
}
</style>
