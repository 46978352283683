<template>
    <div class="main-container">
      <div class="left-container">
        <img src="@/assets/pexels-photo-4069292.jpg" alt="Image" class="main-image" />
      </div>
      <div class="right-container">
        <h2 class="underlined-heading">What We Do?</h2>
        <p>At Vuega, we empower organizations to reach their most ambitious goals with cutting-edge AI and IoT solutions. Our innovative platform enables teams to effortlessly design and deploy intelligent systems that streamline operations and transform data into actionable insights, driving efficiency and agility across the enterprise.</p>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    name: 'SplitContainer'
  }
  </script>
  
  <style scoped>
  .main-container {
    display: flex;
    width: 75%;
    height: 770px;
    margin: 0 auto;
    background-color: white;
    border-radius: 8px;
    /* box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); */
    margin-top: 150px;
  }
  
  .left-container {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: start;
    /* padding: 20px; */
  }
  
  .main-image {
    max-width: 100%;
    max-height: 100%;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .right-container {
    flex: 1;
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  
  .underlined-heading {
    font-family: "Montserrat", sans-serif;
    font-size: 42px;
    font-weight: 700;
    margin-bottom: 30px;
    position: relative;
  }
  
  .underlined-heading::after {
    content: "";
    display: block;
    width: 20%;
    height: 4px;
    background-color: #008bff;
    position: absolute;
    bottom: -10px;
    left: 0;
  }
  
  p {
    font-family: "Montserrat", sans-serif;
    font-size: 16px;
    line-height: 24px;
  }
  </style>
  