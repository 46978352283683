<template>
    <div class="container">
      <div class="section left">
        <h2>Seamless Integration</h2>
        <img src="@/assets/1-5.jpg" alt="Left Image" />
        <p>Our IoT experts ensure seamless integration of IoT devices with your existing systems, enhancing overall performance and efficiency.</p>
      </div>
      <div class="section middle">
        <h2>Data-Driven Insights</h2>
        <img src="@/assets/2-1.jpg" alt="Middle Image" />
        <p>During the IoT development process, you'll collect valuable data that provides deeper insights into your operations, helping you make informed decisions and improve your business strategies.</p>
      </div>
      <div class="section right">
        <h2>Proactive Maintenance</h2>
        <img src="@/assets/3-e1677677389877.png" alt="Right Image" />
        <p>By identifying potential issues early on, our IoT solutions enable proactive maintenance, minimizing downtime and preventing costly disruptions.</p>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    name: 'ThreeSection'
  };
  </script>
  
  <style scoped>
  .container {
    height: 100px;
    width: 75%;
    display: flex;
    justify-content: space-between;
  }
  
  .section {
    width: 30%; /* Adjust width as needed */
    text-align: center;
  }
  
  /* .left, .middle, .right {
    padding: 100px;
  } */
  h2 {
  font-family: "Montserrat", sans-serif;
  font-size: 32px !important;
  font-weight: 6000px;
  line-height: 32px !important;
  margin: 20px 0 10px; /* Space around the subheadings */
  margin-bottom: 40px;
  margin-top: 30px;
}
  p {
    font-family: "Montserrat", sans-serif;
    line-height: 28px;
    padding: 23px 0 0;
    font-size: 16px !important;
    font-weight: 400 !important;
    margin-top: 0;/* Remove default margin to eliminate space between headings */
    
  }
  
  img {
    max-width: 100%;
    height: auto;
  }
  </style>
  