<template>
    <div class="main-container">
      <div class="inner-container">
        <img src="@/assets/image3.jpg" alt="Image 1" class="inner-image">
        <h2>Python Developer</h2>
        <p>We're seeking passionate Python Developers to join our dynamic team. If you excel in writing clean, efficient code and have a knack for solving complex problems.</p>
        <button>Discover More</button>
      </div>
      <div class="inner-container">
        <img src="@/assets/image4.jpg" alt="Image 2" class="inner-image">
        <h2>UI Designers</h2>
        <p>Join our creative team If you have a keen eye for design, a passion for creating intuitive user interfaces, and a drive to deliver exceptional digital experiences, we want you on board.</p>
        <button>Discover More</button>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    name: 'TwoContainers2',
    components: {}
  }
  </script>
  
  <style scoped>
  .main-container {
    width: 80%;
    margin: 0 auto; /* Center horizontally */
    display: flex;
    justify-content: space-between; /* Space between inner containers */
    flex-wrap: wrap; /* Ensure wrapping on smaller screens */
    padding: 20px; /* Add padding to the main container */
    box-sizing: border-box; /* Include padding in the width/height calculations */
  }
  
  .inner-container {
    width: calc(50% - 10px); /* Adjust width to fit two containers with space */
    margin: 10px 0; /* Space between the top and bottom of the containers */
    border-radius: 10px; /* Rounded corners */
    overflow: hidden; /* Ensure rounded corners apply to the image */
    background-color: white; /* Optional: background color */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Initial shadow */
    box-sizing: border-box; /* Include padding/border in the width/height calculations */
    display: flex;
    flex-direction: column; /* Stack elements vertically */
    align-items: center; /* Center content horizontally */
    padding: 20px; /* Add padding inside the container */
    transition: box-shadow 0.3s ease; /* Smooth transition for shadow effect */
  }
  
  .inner-container:hover {
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2); /* Enhanced shadow on hover */
  }
  
  .inner-image {
    width: 100%;
    height: 186px; /* Fixed height */
    object-fit: cover; /* Ensure the image covers the area */
    border-radius: 10px 10px 0 0; /* Rounded corners for the top part of the container */
  }
  
  h2 {
    font-family: "Montserrat", sans-serif;
    font-size: 24px;
    font-weight: bold;
    color: #333;
    margin: 20px 0 10px; /* Margin around the heading */
    text-align: center; /* Center the heading text */
  }
  
  p {
    font-family: "Montserrat", sans-serif;
    font-size: 16px;
    color: #555;
    margin: 0 0 20px; /* Margin around the paragraph */
    text-align: center; /* Center the paragraph text */
    line-height: 30px;

  }
  
  button {
    font-family: "Kanit", sans-serif;
    font-weight: 500;
    font-style: normal;
    font-size: 20px;
    color: white;
    background-color: #008bff;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  button:hover {
    background-color: #006fcc;
  }
  </style>
  