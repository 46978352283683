<template>
    <div class="red-container">
      <div class="grid-container">
        <div class="grid-item first-item">
          <div class="text-content">
            <h1>Dedicated team</h1>
            <p>Our AI, ML, and DL experts are deeply passionate about their work, demonstrated by their commitment to staying at the forefront of the latest advancements and best practices in artificial intelligence. Their enthusiasm drives the innovation and excellence that define our solutions.</p>
          </div>
        </div>
  
        <div class="grid-item second-item">
          <img src="@/assets/pexels-photo-8294560.jpg" alt="Mobile App" />
        </div>
        
        <div class="grid-item text-item">
          <h1>Open communication</h1>
          <p>Total transparency and open communication are our hallmarks. We use dedicated channels to ensure that all team members can freely share critical information and ideas, fostering collaboration and ensuring the successful execution of AI projects.</p>
        </div>
  
        <div class="grid-item second-item">
          <img src="@/assets/pexels-photo-8438918.jpg" alt="image" />
        </div>
  
        <div class="grid-item text-item">
          <h1>Agile development</h1>
          <p>To achieve the most efficient development, we apply Agile methodologies such as Scrum. We continuously evaluate project goals and work closely with our clients to implement necessary adjustments, ensuring our AI solutions are agile and adaptive.</p>
        </div>
  
        <div class="grid-item second-item">
          <img src="@/assets/pexels-photo-8294624.jpg" alt="image" />
        </div>
  
        <div class="grid-item text-item">
          <h1>Budget management</h1>
          <p>We carefully estimate and manage the budget for each AI, ML, and DL task, taking full responsibility for financial planning. Regular reports on project spending and timelines ensure you stay informed, allowing you to focus on other important aspects of your project.

</p>
        </div>
  
        <div class="grid-item second-item">
          <img src="@/assets/pexels-photo-8438943.jpg" alt="image" />
        </div>
  
        <div class="grid-item text-item">
          <h1>IP transfer</h1>
          <p>From the outset of our collaboration, you gain full ownership of the Intellectual Property rights for your AI solutions. Any custom technology we develop for you remains exclusive to your business, safeguarding your unique ideas and innovations.</p>
        </div>
  
      </div>
    </div>
  </template>
  
  <script>
  export default {
    name: 'BestCompanyPage',
  }
  </script>
  
  <style scoped>
  .red-container {
    display: grid;
    height: 1000px; /* Set height of the main container */
    width: 75%; /* Set width of the main container */
    background-color: white; /* Set background color to white */
    margin: 0 auto; /* Center horizontally */
    padding: 0; /* Optional: padding inside the container */
    border-radius: 8px; /* Optional: rounded corners */
    box-sizing: border-box; /* Include padding and border in the element's total width and height */
  }
  
  .grid-container {
    display: grid;
    grid-template-columns: repeat(3, 365px); /* 3 columns with fixed width */
    grid-template-rows: repeat(4, 283px); /* 4 rows with fixed height */
    column-gap: 60px; /* Space between containers */
    row-gap: 40px;
    height: 100%; /* Fill the height of the parent */
    box-sizing: border-box; /* Include padding and border in the element's total height */
  }
  
  .grid-item {
    background-color: white; /* Set background color for the items */
    border-radius: 8px; /* Rounded corners for the items */
    display: flex;
    align-items: center;
    justify-content: center;
    width: 400px; /* Set the width of the items */
    height: 300px; /* Set the height of the items */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Light shadow */
    overflow: hidden; /* Ensure that the border-radius is respected */
    transition: transform 0.3s ease, box-shadow 0.3s ease; /* Smooth transition for hover effects */
  }
  
  .grid-item:hover {
    transform: scale(1.02); /* Slightly enlarge the item on hover */
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.3); /* Darker shadow on hover */
  }
  
  .first-item, .text-item {
    display: flex;
    flex-direction: column; /* Arrange heading and paragraph vertically */
    justify-content: center; /* Center items vertically */
    padding: 20px; /* Optional: padding inside the item */
    box-sizing: border-box; /* Include padding in the total width and height */
  }
  
  .first-item h1, .text-item h1 {
    margin: 0 0 10px; /* Space below the heading */
  }
  
  .first-item p, .text-item p {
    margin: 0; /* Remove default margin */
  }
  
  .second-item {
    display: flex; /* Flexbox to handle the image */
    overflow: hidden; /* Ensure images don't overflow their containers */
  }
  
  .second-item img {
    width: 100%; /* Ensure the image fills the container width */
    height: 100%; /* Ensure the image fills the container height */
    object-fit: cover; /* Cover the container with the image */
    border-radius: 8px; /* Optional: rounded corners */
    filter: grayscale(100%); /* Make image grayscale by default */
    transition: filter 0.3s ease; /* Smooth transition for hover effect */
  }
  
  .second-item:hover img {
    filter: grayscale(0%); /* Remove grayscale on hover */
  }
  
  h1 {
    font-family: "Montserrat", sans-serif;
    font-size: 24px !important;
    font-weight: 700 !important;
    line-height: 30px !important;
  }
  
  p {
    font-family: "Montserrat", sans-serif;
    font-size: 16px !important;
    font-weight: 400 !important;
    line-height: 30px !important;
    margin: 0 auto;
  }
  
  .text-item {
    display: flex;
    flex-direction: column; /* Arrange heading and paragraph vertically */
    align-items: flex-start; /* Align items to the start */
  }
  
  .text-item h1, .text-item p {
    margin: 0 0 10px; /* Space below the heading and paragraph */
    text-align: left; /* Align text to the left */
  }
  </style>
  