<template>
  <div>
    <div class="heading-container" ref="headingContainer">
      <AboutCareer />
      <WhereWork />
      <TwoContainers />
      <TwoContainers2/>
      <WhoWeAre/>
      <JoinJourney/>
      <EmpoweringContent/>
      <WorkPlace/>
      <LifeAtVuega/>
    </div>
  </div>
</template>

<script>
import ScrollReveal from 'scrollreveal';
import AboutCareer from '@/CareersComponents/CenteredText.vue';
import WhereWork from '@/CareersComponents/WhereWork.vue';
import TwoContainers from '@/CareersComponents/TwoContainers.vue';
import TwoContainers2 from '@/CareersComponents/TwoContainers2.vue';
import WhoWeAre from '@/CareersComponents/WhoWeAre.vue';
import JoinJourney from '@/CareersComponents/JoinJourney.vue';
import EmpoweringContent from '@/CareersComponents/EmpoweringContent.vue';
import WorkPlace from '@/CareersComponents/WorkPlace.vue';
import LifeAtVuega from '@/CareersComponents/LifeAtVuega.vue';
import { useHead } from '@unhead/vue';

export default {
  name: 'CareersDevelopment',
  components: {
    AboutCareer,
    WhereWork,
    TwoContainers,
    TwoContainers2,
    WhoWeAre,
    JoinJourney,
    EmpoweringContent,
    WorkPlace,
    LifeAtVuega
  },
  setup(){
    useHead({
    title: 'Careers at Vuega - Join Our Team',
    meta: [
      { name: 'description', content: 'Explore career opportunities at Vuega. Join a dynamic team of professionals in AI, web, and mobile development, and grow your career with us.' },
      { name: 'keywords', content: 'vuega jobs, intern, careers, jobs, job opportunities, career growth, AI jobs, web development jobs, mobile development jobs' },
      { property: 'og:title', content: 'Careers at Vuega - Join Our Team' },
      { property: 'og:description', content: 'Looking for a rewarding career in technology? Join Vuega and work on innovative projects in AI, web, and mobile development.' },
      { property: 'og:type', content: 'website' },
    ]
  });
  },
  mounted() {
    const sr = ScrollReveal({
      origin: 'bottom',
      distance: '100px',
      duration: 1500,
      delay: 400,
      reset: false,
      viewFactor: 0.1,
    });

    sr.reveal(this.$refs.headingContainer);
  }
}
</script>

<style scoped>
html, body {
  margin: 0;
  padding: 0;
  height: 100%; /* Ensure the body is at least the height of the viewport */
  scroll-behavior: smooth; /* Enable smooth scrolling */
}

.heading-container {
  width: 100%;
  background-color: white; /* Background color */
  display: flex;
  flex-direction: column; /* Stack components vertically */
  align-items: center; /* Center components horizontally */
  justify-content: center; /* Center components vertically */
  margin: 0; /* Remove any default margin */
  padding: 0; /* Remove any default padding */
  box-sizing: border-box; /* Ensure padding and border are included in width/height calculations */
}

h1 {
  font-family: "Montserrat", sans-serif;
  font-size: 49px !important;
  font-weight: 700 !important;
  line-height: 57px !important;
  font-style: normal;
  margin: 0; /* Remove default margin to eliminate space between headings */
}
</style>
