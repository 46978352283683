<template>
    <div class="main-container">
      <div class="left-container">
        <h2>Schedule a free expert session
            with the COO of Vuega</h2>
        <div class="profile-container">
          <img src="@/assets/Vishnu_profile.jpeg" alt="Profile Image" class="profile-image" />
          <h3>Vishnu Ram M</h3>
          <h4>Chief Operating Officer</h4>
          <p>I would love to share my experiences with you and talk about the challenges you are facing! Feel free to reach out to me to discuss your ideas and needs for any project.</p>
        </div>
      </div>
      <div class="right-container">
        <h2>Contact Us</h2>
        <form class="contact-form">
          <label for="name">Name</label>
          <input type="text" id="name" name="name" required />
  
          <label for="email">Email</label>
          <input type="email" id="email" name="email" required />
  
          <label for="phone">Phone Number</label>
          <input type="tel" id="phone" name="phone" required />
  
          <label for="message">How can I help?</label>
          <textarea id="message" name="message" required></textarea>
  
          <div class="checkboxes">
            <label>
              <input type="checkbox" name="agree1" required /> I agree to the terms and conditions
            </label>
            <label>
              <input type="checkbox" name="agree2" required /> I agree to receive newsletters
            </label>
          </div>
  
          <button type="submit" class="submit-button">Submit</button>
        </form>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    name: 'ContactSection'
  }
  </script>
  
  <style scoped>
  .main-container {
    display: flex;
    width: 75%;
    margin: 0 auto;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    padding: 20px;
    background-color: white;
  }
  
  .left-container, .right-container {
    flex: 1;
    margin: 20px;
  }
  
  .left-container h2, .right-container h2 {
    font-family: "Montserrat", sans-serif;
    font-size: 24px;
    font-weight: 600;
    margin-bottom: 20px;
  }
  
  .profile-container {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    padding: 20px;
    border-radius: 8px;
    background-color: #f9f9f9;
    text-align: center;
  }
  
  .profile-image {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    margin-bottom: 15px;
  }
  
  .profile-container h3 {
    font-family: "Montserrat", sans-serif;
    font-size: 20px;
    font-weight: 600;
    margin: 10px 0;
  }
  
  .profile-container h4 {
    font-family: "Montserrat", sans-serif;
    font-size: 16px;
    font-weight: 400;
    margin: 5px 0;
  }
  
  .profile-container p {
    font-family: "Montserrat", sans-serif;
    font-size: 14px;
    color: #666;
  }
  
  .contact-form {
    display: flex;
    flex-direction: column;
  }
  
  .contact-form label {
    font-family: "Montserrat", sans-serif;
    font-size: 16px;
    margin-bottom: 5px;
  }
  
  .contact-form input, .contact-form textarea {
    font-family: "Montserrat", sans-serif;
    font-size: 16px;
    padding: 10px;
    margin-bottom: 20px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  .contact-form .checkboxes label {
    font-family: "Montserrat", sans-serif;
    font-size: 14px;
    margin-bottom: 10px;
  }
  
  .contact-form .submit-button {
    font-family: "Montserrat", sans-serif;
    font-size: 16px;
    background-color: #008bff;
    color: white;
    padding: 10px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .contact-form .submit-button:hover {
    background-color: #007bff;
  }
  </style>
  