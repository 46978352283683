<template>
    <span class="heading-container">
      <h1>Your Software</h1>
      <h1>development Company!</h1>
    </span>
  </template>
  
  <script>
  export default {
    name: 'AboutText',
    components: {}
  }
  </script>
  
  <style scoped>
  .heading-container {
    display: flex; /* Aligns children (h1) in a row */
    flex-direction: column; /* Stacks headings vertically */
    margin: 0; /* Remove any default margin */
    padding: 0; /* Remove any default padding */
  }
  
  h1 {
    font-family: "Montserrat", sans-serif;
    font-size: 49px !important;
    font-weight: 700 !important;
    line-height: 57px !important;
    font-style: normal;
    margin: 0; /* Remove default margin to eliminate space between headings */
  }
  </style>
  