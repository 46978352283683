<template>
  <div class="soft-cmp">
    <h1>AI project in a nutshell</h1>
    <p>
      From conception to deployment and ongoing maintenance – every machine learning project at our firm follows a well-defined framework to ensure high-quality solutions delivered efficiently. 
    </p>
    <div class="section">
      <h2>Discovery & Requirements Gathering</h2>
      <p>
        This initial phase involves understanding and assessing your ML project’s goals. We focus on gaining a comprehensive understanding of your needs to create a detailed plan that aligns with your objectives.      </p>
    </div>
    <div class="section">
      <h2>Collection & Preprocessing</h2>
      <p>
        Here, we gather and prepare data essential for training your ML models. This process involves collecting relevant datasets, cleaning, and transforming the data to ensure it's suitable for model training.      </p>
    </div>
    <div class="section">
      <h2>Development & Validation</h2>
      <p>
        At this stage, we develop and train your ML models using the prepared data. Following best practices, we apply various algorithms and techniques to create models that meet your requirements. We rigorously test and validate these models to ensure they perform well and deliver the desired results.      </p>
    </div>
    <div class="section">
      <h2>Deployment & Integration</h2>
      <p>
        Once the models are validated, we prepare them for deployment. This includes integrating the models into your existing systems and ensuring they function correctly in a production environment. We also conduct performance tests to confirm the models handle real-world data and traffic effectively.

</p>
    </div>
    <div class="section">
      <h2>Monitoring & Maintenance</h2>
      <p>
        Post-deployment, we provide ongoing support to ensure the continued success of your ML solution. This includes monitoring model performance, addressing any issues, and updating the models with new data or improvements as necessary.      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'WebNutshell'
}
</script>

<style scoped>
.soft-cmp {
  background-color: white; /* Background color */
  padding: 20px 20px 20px 0; /* Padding: Top, Right, Bottom, Left */
  border-radius: 8px; /* Rounded corners */
  text-align: start; /* Text alignment */
  margin: 20px auto; /* Center the container and add top/bottom margin */
  max-width: 75%; /* Limit the max width */
  margin-top: 100px;
  position: relative; /* Ensure the pseudo-element is positioned relative to this container */
}

h1 {
  font-family: "Montserrat", sans-serif;
  font-size: 40px !important;
  font-weight: 700 !important;
  line-height: 48px !important;
  margin: 0; /* Remove default margin to eliminate space between headings */
  margin-bottom: 40px;
  position: relative; /* Ensure the pseudo-element is positioned relative to the h1 */
}

h1::after {
  content: "";
  display: block;
  width: 5%; /* Width of the underline */
  height: 5px; /* Height of the underline */
  background-color: #008bff; /* Color of the underline */
  position: absolute; /* Position it at the bottom of the h1 */
  bottom: -10px; /* Align it slightly below the h1 */
  left: 0; /* Start from the left */
}

h2 {
  font-family: "Montserrat", sans-serif;
  font-size: 32px !important;
  font-weight: 6000px;
  line-height: 32px !important;
  margin: 20px 0 10px; /* Space around the subheadings */
  margin-bottom: 40px;
  margin-top: 30px;
}

p {
  font-family: "Montserrat", sans-serif;
  font-size: 16px !important;
  line-height: 24px !important;
  margin: 0 0 20px; /* Space below paragraphs */
}
</style>
