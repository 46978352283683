<template>
    <div class="para-container">
      <p>Ignite your business growth with top-notch custom IoT and embedded systems</p>
      <p>tailored to your needs. With our experienced team at the helm</p>
      <p>you'll harness the power of leading technologies to enhance</p>
      <p> your operational efficiency and digital journey.</p>
      <!-- <p>customer experience. Provide your audience with innovative and reliable solutions across various devices and platforms.</p> -->
      <div class="talk-btn">
        <LetsTalk/>
      </div>
    </div>
  </template>
  
  <script>
  import LetsTalk from '@/components/LetsTalk.vue';
  
  export default {
    name: 'AboutPara',
    components: {
      LetsTalk,
    }
  }
  </script>
  
  <style scoped>
  p {
    font-family: "Montserrat", sans-serif;
    font-weight: 400 !important;
    line-height: 25px !important;
    margin: 0; /* Remove default margin to eliminate space between headings */
    font-size: 16px;  
    margin: 0;
  }
  
  .para-container {
    padding-bottom: 40px; /* Add padding to the bottom of the container */
  }
  
  .talk-btn {
    margin-top: 20px; /* Move the button down */
  }
  </style>
  