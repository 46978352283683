<template>
    <div class="para-container">
      <p>Ignite your business growth with a cutting-edge AI <br>solution tailored to your needs. With our experienced team at the helm, </p>
      <p>you will harness the power of advanced technologies to transform</p>
      <p>your data into actionable insights. Provide your organization</p>
      <p> with a unique AI-driven experience that enhances <br>decision-making and drives innovation across all aspects of your operations.</p>
      <div class="talk-btn">
        <LetsTalk/>
      </div>
    </div>
  </template>
  
  <script>
  import LetsTalk from '@/components/LetsTalk.vue';
  
  export default {
    name: 'AboutPara',
    components: {
      LetsTalk,
    }
  }
  </script>
  
  <style scoped>
  p {
    font-family: "Montserrat", sans-serif;
    font-weight: 400 !important;
    line-height: 25px !important;
    margin: 0; /* Remove default margin to eliminate space between headings */
    font-size: 16px;  
    margin: 0;
  }
  
  .para-container {
    padding-bottom: 40px; /* Add padding to the bottom of the container */
  }
  
  .talk-btn {
    margin-top: 20px; /* Move the button down */
  }
  </style>
  