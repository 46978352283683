<template>
  <div class="nav-bar">
    <div class="nav-wrapper">
      <router-link to="/" class="logo-link">
        <!-- <img src="@/assets/logo.jpg" alt="Logo" class="logo" /> -->
      </router-link>
      <router-link to="/" class="vuega">VUEGA</router-link>
      <nav>
        <ul class="nav-links">
          <li class="dropdown">
            <a href="#" @click.prevent="toggleDropdown">Services</a>
            <ul class="dropdown-menu" :class="{ 'show': isDropdownVisible }">
              <li><router-link to="/ai-development">AI Solutions</router-link></li>
              <li><router-link to="/mobile-app-development">Mobile App Development</router-link></li>
              <li><router-link to="/web-development">Web Development</router-link></li>
              <li><router-link to="/iot-development">IoT Development</router-link></li>
              <!-- Add more options here -->
            </ul>
          </li>
          <li><router-link to="/web-development">Web App Development</router-link></li>
          <li><router-link to="/design-catalogue">Design Catalogue</router-link></li>
          <li><router-link to="/about-us">About Us</router-link></li>
          <li><router-link to="/career">Career</router-link></li>
          <li class="blue-container">
            <font-awesome-icon :icon="['fas', 'comment-alt']" />
            <span>Let's Talk</span>
          </li>
        </ul>
      </nav>
    </div>
  </div>
</template>






<script>
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faCommentAlt } from '@fortawesome/free-solid-svg-icons'; 

// Add FontAwesome icons to the library
library.add(faCommentAlt);

export default {
  name: "NavigationBar",
  components: {
    FontAwesomeIcon,
  },
  data() {
    return {
      isDropdownVisible: false
    };
  },  
  methods: {
    toggleDropdown() {
      this.isDropdownVisible = !this.isDropdownVisible;
    }
  },
  mounted() {
    let lastScrollTop = 0;
    window.addEventListener('scroll', () => {
      const currentScroll = window.pageYOffset || document.documentElement.scrollTop;
      const navBar = document.querySelector('.nav-bar');
      
      if (currentScroll > lastScrollTop) {
        // Scrolling down
        navBar.classList.add('hidden');
      } else {
        // Scrolling up
        navBar.classList.remove('hidden');
      }
      
      lastScrollTop = currentScroll <= 0 ? 0 : currentScroll; // For Mobile or negative scrolling
    });
  }
}
</script>



<style scoped>
.nav-bar {
  background-color: #f8f9fa;
  padding: 1px 2px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3); /* Shadow for nav-bar */
  display: flex;
  justify-content: center; /* Center the entire nav-wrapper */
  position: fixed; /* Fixed position at the top */
  top: 0; /* Align to the top of the viewport */
  left: 0; /* Align to the left of the viewport */
  width: 100%; /* Full width */
  transition: transform 0.3s ease; /* Smooth transition */
  z-index: 1000; /* Ensure it stays on top */
  height: 80px; /* Fixed height for navbar */
}

.nav-bar.hidden {
  transform: translateY(-100%); /* Move it out of view */
}

.nav-wrapper {
  display: flex;
  align-items: center; /* Center items vertically */
  height: 100%; /* Ensure it takes full height of the navbar */
}

.logo-link {
  display: flex;
  align-items: center; /* Vertically center the logo */
  text-decoration: none; /* Remove underline from the link */
}

.logo {
  height: 40px; /* Adjust the size as needed */
  margin-left: -100px; /* Move the logo further to the left */
}

nav {
  flex-grow: 1;
  display: flex;
  justify-content: center;
}

.nav-links {
  list-style: none;
  padding: 0;
  display: flex; /* Align nav links horizontally */
  align-items: center; /* Vertically center nav links */
  margin-left: 200px;
}

.nav-links li {
  margin-left: 65px; /* Space between links */
  position: relative; /* For positioning dropdown */
}

.nav-links a {
  text-decoration: none;
  color: black;
  font-family: "Kanit", sans-serif;
  font-weight: 400;
}

.nav-links a:hover {
  color: #007bff; /* Color on hover */
}

li {
  font-family: "Kanit", sans-serif;
  font-weight: 400;
  font-style: normal;
}

.vuega {
  text-decoration: none;
  font-family: "Kanit", sans-serif;
  font-weight: 400;
  color: #333;
  font-weight: bold;
  font-size: 40px;
  margin-left: 90px; /* Adjust this value to move it to the left */
}

/* New style for the blue container */
.blue-container {
  font-family: "Kanit", sans-serif;
  font-weight: 400;
  font-style: normal;
  
  display: flex;
  align-items: center;
  justify-content: center; /* Center content horizontally and vertically */
  background-color: #0435f9; /* Blue background color */
  padding: 10px; /* Padding inside the button */
  border-radius: 6px; /* Rounded corners */
  color: white; /* Text color inside the blue container */
  font-weight: bold;
  font-size: 16px; /* Font size */
  transition: background-color 0.3s ease; /* Smooth transition for background color */
}

.blue-container svg {
  margin-right: 10px; /* Space between icon and text */
  font-size: 18px; /* Adjusted icon size to fit the button */
}

.blue-container:hover {
  background-color: #021a7a; /* Change background color on hover */
}

/* Dropdown Menu Styles */
.dropdown {
  position: relative;
}

.dropdown-menu {
  display: none;
  position: absolute;
  top: 100%;
  left: 0;
  background-color: #f8f9fa;
  list-style: none;
  padding: 10px;
  margin: 0;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3); /* Shadow for dropdown */
  z-index: 10;
  width: 200px;
}

.dropdown-menu li {
  margin: 0;
}

.dropdown-menu a {
  display: block;
  padding: 10px;
  color: black;
  text-decoration: none;
}

.dropdown-menu a:hover {
  background-color: #0435f9; /* Background color on hover */
  color: white;
}

.dropdown:hover .dropdown-menu {
  display: block;
}
</style>

