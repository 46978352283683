<template>
    <div>
    <div class="soft-cmp">
        
        <p>Achieving a competitive advantage requires an AI solution that is technologically advanced, highly effective, and seamlessly integrates with your operations. </p>
        <p>Our AI solutions firm will assist you in meeting these needs, providing expert guidance throughout the entire development process. Discover why we are your ideal partner for delivering innovative and impactful AI solutions.</p>

    </div>

    
</div>
  </template>
  
  <script>
  export default {
    name: 'TopWebDesignText'
  }
  </script>
  
  <style scoped>
  .soft-cmp {
    align-content: flex-start;
    justify-content: start;
    text-align: start;
    background-color: white; /* Background color */
    padding: 20px 20px 20px 0; /* Padding: Top, Right, Bottom, Left */
    border-radius: 8px; /* Rounded corners */
    text-align: start; /* Text alignment */
    margin: 20px auto; /* Center the container and add top/bottom margin */
    max-width: 75%; /* Limit the max width */
    position: relative;
    max-height: 10px;
  }
  
  p {
    font-family: "Montserrat", sans-serif;
    line-height: 28px;
    padding: 23px 0 0;
    font-size: 16px !important;
    font-weight: 400 !important;
    margin-top: 0;/* Remove default margin to eliminate space between headings */
    
  }
  </style>
  