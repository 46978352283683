<template>
  <div>
    <div class="container" v-scroll-reveal>
      <div class="cn-1">
        <div class="text-container">
          <About_text class="about-text" />
          <AboutPara class="para-text" />
        </div>
        <WebImage class="software-company" v-scroll-reveal/>
      </div>
    </div>
    <span >
      <TopWebDesign class="software-company" v-scroll-reveal/>
      <TopWebDesignText class="software-company" v-scroll-reveal/>

      <WebDevTeam class="software-company" v-scroll-reveal/>
      <MeetWebDevs class="software-company" v-scroll-reveal/>

      <div class="software-company" v-scroll-reveal>
      <BestCompany v-scroll-reveal/>
      <BestCompanyText class="software-company" v-scroll-reveal/>
      <BestCompanyPage class="software-company" v-scroll-reveal/>
    </div>

      <ConsultantHeading class="software-company" v-scroll-reveal/>
      <Consultant v-scroll-reveal/>
    </span>

    <div class="software-company from-left" v-scroll-reveal>
      <ClientValueUs class="software-company" v-scroll-reveal/>
      <ClientValueUsText class="software-company" v-scroll-reveal/>
      <ClientValueUsPage v-scroll-reveal/>
    </div>

    <div class="software-company from-right" v-scroll-reveal>
      <ReadmoreAboutUs class="software-company" v-scroll-reveal/>
      <WeVuega  v-scroll-reveal/>
    </div>

    <div class="software-company" v-scroll-reveal>
      <FreeConsultation />

      <WebNutshell class="software-company" v-scroll-reveal/>

      <ExploreMore class="software-company" v-scroll-reveal/>
      <ExploreMoreText class="software-company" v-scroll-reveal/>

      <MobileApp class="software-company" v-scroll-reveal/>
      <WebDevelopment class="software-company" v-scroll-reveal/>

      <ContactSection class="software-company" v-scroll-reveal/>

      <DummyContainer />
    </div>
  </div>
</template>

<script>
import ScrollReveal from 'scrollreveal';
import About_text from '@/AIDevelopmentComponents/About_text.vue';
import WebImage from '@/AIDevelopmentComponents/WebImage.vue';
import AboutPara from '@/AIDevelopmentComponents/AboutPara.vue';

import TopWebDesign from '@/AIDevelopmentComponents/TopWebDesign.vue';
import TopWebDesignText from '@/AIDevelopmentComponents/TopWebDesignText.vue';

import WebDevTeam from '@/AIDevelopmentComponents/WebDevTeam.vue';
import MeetWebDevs from '@/AIDevelopmentComponents/MeetWebDevs.vue';

import BestCompany from '@/AIDevelopmentComponents/BestCompany.vue';
import BestCompanyText from '@/AIDevelopmentComponents/BestCompanyText.vue';
import BestCompanyPage from '@/AIDevelopmentComponents/BestCompanyPage.vue';

import FreeConsultation from '@/AIDevelopmentComponents/FreeConsultation.vue';

import WebNutshell from '@/AIDevelopmentComponents/WebNutshell.vue';

import ConsultantHeading from '@/AIDevelopmentComponents/ConsultantHeading.vue';
import Consultant from '@/AIDevelopmentComponents/Consultant.vue';

import ExploreMore from '@/AIDevelopmentComponents/ExploreMore.vue';
import ExploreMoreText from '@/AIDevelopmentComponents/ExploreMoreText.vue';

import WebDevelopment from '@/components/WebDevelopment.vue';
import MobileApp from '@/components/MobileApp.vue';

import ContactSection from '@/AIDevelopmentComponents/ContactSection.vue';

import DummyContainer from '@/AIDevelopmentComponents/DummyContainer.vue';

import { useHead } from '@unhead/vue';


export default {
  name: 'WebDevelopmentPage',
  components: {
    WebDevelopment,
    About_text,
    WebImage,
    AboutPara,
    TopWebDesign,
    TopWebDesignText,
    WebDevTeam,
    MeetWebDevs,
    BestCompany,
    BestCompanyText,
    BestCompanyPage,
    FreeConsultation,
    WebNutshell,
    ConsultantHeading,
    Consultant,
    ExploreMore,
    ExploreMoreText,
    MobileApp,
    ContactSection,
    DummyContainer,
  },
  setup(){
    useHead({
      title: 'AI Development Services - Vuega',
      meta: [
        { name: 'description', content: 'Explore cutting-edge AI development services with Vuega. From machine learning models to intelligent automation, we help businesses harness the power of AI.' },
        { name: 'keywords', content: 'Vuega, Vuega AI, VUEGAai, GenAI, startups, GenAI startups, AI development, machine learning, artificial intelligence, AI services, intelligent automation' },
        { property: 'og:title', content: 'AI Development Services - Vuega' },
        { property: 'og:description', content: 'Discover how Vuega’s AI development services can transform your business. Our expertise in AI can drive innovation and efficiency.' },
        { property: 'og:type', content: 'website' },
      ]
    });
  },
  mounted() {
    const sr = ScrollReveal({
      origin: 'bottom',
      distance: '100px',
      duration: 1500,
      delay: 400,
      reset: false,
      viewFactor: 0.1,
    });

    sr.reveal('.container');
    sr.reveal('.software-company', { interval: 300 });
    sr.reveal('.software-company > *', { interval: 300 });

    sr.reveal('.from-left', {
      origin: 'left',
      distance: '400px',
    });

    sr.reveal('.from-right', {
      origin: 'right',
      distance: '400px',
    });
  },
};
</script>

<style scoped>
.container,
.software-company {
  visibility: hidden; /* Ensure elements are initially hidden */
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translate3d(0, 300px, 0);
  }
  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

.container {
  height: 600px;
  max-width: 75%;
  background-color: white; /* Change to your desired background color */
  padding: 10px 15px; /* Padding around the container */
  border-radius: 6px; /* Optional: rounded corners */
  margin: 0 auto; /* Center horizontally */
  margin-top: 180px;
  animation: fadeInUp 1s ease-in-out forwards; /* Add animation */
}

.cn-1 {
  display: flex;
  justify-content: space-between; /* Pushes items to the edges */
  align-items: flex-start; /* Align items to the top */
}

.text-container {
  margin-top: 50px;
  display: flex;
  flex-direction: column; /* Stack children vertically */
}

.about-text {
  margin-top: 0; /* Adjust this value to move AboutText higher or lower */
}

.para-text {
  margin-top: 35px; /* Adjust the space between AboutText and Para */
}

.working-image {
  align-self: flex-end;
}
</style>
