<template>
    <div class="soft-cmp">
      <h1>Why do our clients value us?</h1>
    </div>
  </template>
  
  <script>
  export default {
    name: 'ClientValueUs',
  }
  </script>
  
  <style scoped>
  .soft-cmp {
    background-color: white; /* Background color */
    padding: 20px 20px 20px 0; /* Padding: Top, Right, Bottom, Left */
    border-radius: 8px; /* Rounded corners */
    text-align: start; /* Text alignment */
    margin: 20px auto; /* Center the container and add top/bottom margin */
    max-width: 75%; /* Limit the max width */
    position: relative; /* Ensure the pseudo-element is positioned relative to this container */
    margin-top: 60px;
  }
  
  h1 {
    font-family: "Montserrat", sans-serif;
    font-size: 40px !important;
    font-weight: 700 !important;
    line-height: 48px !important;
    margin: 0; /* Remove default margin to eliminate space between headings */
  }
  
  .soft-cmp::after {
    content: "";
    display: block;
    width: 5%; /* Full width of the container */
    height: 5px; /* Height of the line */
    background-color: #008bff; /* Line color */
    position: absolute; /* Position it at the bottom */
    bottom: 0; /* Align it to the bottom */
    left: 0; /* Start from the left */
  }
  </style>
  