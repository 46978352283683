<template>
  <div class="soft-cmp">
    <h1>Step-by-step guide to our mobile development process</h1>
    <p>
      Since we recognize the uniqueness of each project, we provide end-to-end mobile app development services suited to meet your business domain and budgetary limitations. Whether you have a ready-made idea or need guidance to validate your concept, we will assist you in driving your application’s vision from ideation to completion. Our development process is always well-structured to produce quality products within a competitively short turnaround. This is how it works in stages.
    </p>
    <div class="section">
      <h2>Product Discovery Workshops</h2>
      <p>
        We begin with the ideation stage to obtain a thorough knowledge of your concept and provide a shared understanding of the product objectives. At this point, we discuss your business goals and do market research to obtain a proper perspective on competition and target audience demands. Based on this information, we plan the product’s architecture while assessing the risk and required budget. The result of Product Discovery Workshops is a clear strategy for product deployment with a detailed development roadmap.
      </p>
    </div>
    <div class="section">
      <h2>UI/UX Design</h2>
      <p>
        After reaching a common understanding of your product vision and developing a plan for its implementation, we take the wheel, starting with the UX/UI design. Our team operates on user personas to create basic wireframes and transform them into interactive prototypes that present micro-interactions. Throughout this stage, the designers stick to your business assumptions to guarantee that the final appearance of the app aligns with your brand.      </p>
    </div>
    <div class="section">
      <h2>Agile Development & QA</h2>
      <p>
        The coding finally begins! Our team follows the Scrum Methodology, which entails a Project Manager prioritizing tasks and assigning them to developers, who complete them in regular Sprints. Each code section is tested on the spot, and any bugs discovered are instantly returned for further refinement. When all components are in place, the mobile app goes through a thorough Quality Assurance process to ensure correct operation.
      </p>
    </div>
    <div class="section">
      <h2>Launch</h2>
      <p>
        Following the development and testing phases, we proceed to the app launch preparation, which entails building a release build of your app and preparing it for distribution on the proper app marketplace. We carefully attend to every aspect to minimize the risk of downtime and ensure that your mobile software is deployed on schedule.
      </p>
    </div>
    <div class="section">
      <h2>Maintenance</h2>
      <p>
        Vuega values long-term collaborations and will not neglect your project after the product launch. Outside of your application design and development, we also take care of maintenance to ensure that it stays current and relevant. It means we address any bugs or issues that arise, implement security measures, and keep the app current with the latest technologies to keep it functioning optimally.
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'WebNutshell'
}
</script>

<style scoped>
.soft-cmp {
  background-color: white; /* Background color */
  padding: 20px 20px 20px 0; /* Padding: Top, Right, Bottom, Left */
  border-radius: 8px; /* Rounded corners */
  text-align: start; /* Text alignment */
  margin: 20px auto; /* Center the container and add top/bottom margin */
  max-width: 75%; /* Limit the max width */
  margin-top: 100px;
  position: relative; /* Ensure the pseudo-element is positioned relative to this container */
}

h1 {
  font-family: "Montserrat", sans-serif;
  font-size: 40px !important;
  font-weight: 700 !important;
  line-height: 48px !important;
  margin: 0; /* Remove default margin to eliminate space between headings */
  margin-bottom: 40px;
  position: relative; /* Ensure the pseudo-element is positioned relative to the h1 */
}

h1::after {
  content: "";
  display: block;
  width: 5%; /* Width of the underline */
  height: 5px; /* Height of the underline */
  background-color: #008bff; /* Color of the underline */
  position: absolute; /* Position it at the bottom of the h1 */
  bottom: -10px; /* Align it slightly below the h1 */
  left: 0; /* Start from the left */
}

h2 {
  font-family: "Montserrat", sans-serif;
  font-size: 32px !important;
  font-weight: 6000px;
  line-height: 32px !important;
  margin: 20px 0 10px; /* Space around the subheadings */
  margin-bottom: 40px;
  margin-top: 30px;
}

p {
  font-family: "Montserrat", sans-serif;
  font-size: 16px !important;
  line-height: 24px !important;
  margin: 0 0 20px; /* Space below paragraphs */
}
</style>
