<template>
    <div class="para-container">
      <p>Establish a solid digital presence with our web development company.</p>
      <p>We specialize in building unique web-based solutions with advanced</p>
      <p>technologies to help companies fulfill their goals without a hitch. Our</p>
      <p>skilled team will work closely with you to create a stunning custom web</p>
      <p>app that exceeds your expectations.</p>
      <div class="talk-btn">
        <LetsTalk/>
      </div>
    </div>
  </template>
  
  <script>
  import LetsTalk from '@/components/LetsTalk.vue';
  
  export default {
    name: 'AboutPara',
    components: {
      LetsTalk,
    }
  }
  </script>
  
  <style scoped>
  p {
    font-family: "Montserrat", sans-serif;
    font-weight: 400 !important;
    line-height: 25px !important;
    margin: 0; /* Remove default margin to eliminate space between headings */
    font-size: 16px;  
    margin: 0;
  }
  
  .para-container {
    padding-bottom: 40px; /* Add padding to the bottom of the container */
  }
  
  .talk-btn {
    margin-top: 20px; /* Move the button down */
  }
  </style>
  