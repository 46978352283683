<template>
  <div>
    <div class="container" v-scroll-reveal>
      <div class="cn-1">
        <div class="text-container">
          <About_text class="about-text" />
          <AboutPara class="para-text" />
        </div>
        <WebImage />
      </div>
    </div>
    <span class="software-company" v-scroll-reveal>
      <TopWebDesign class="software-company" v-scroll-reveal/>
      <TopWebDesignText class="software-company" v-scroll-reveal/>
      <QualityThat class="software-company" v-scroll-reveal/>
      <QualityText class="software-company" v-scroll-reveal/>
      <SplitContainer class="software-company" v-scroll-reveal/>
      <WebDevTeam class="software-company" v-scroll-reveal/>
      <MeetWebDevs class="software-company" v-scroll-reveal/>
      <IOTHeading v-scroll-reveal/>
      <IOTText v-scroll-reveal/>
      <ThreeSection v-scroll-reveal/>
      <FreeConsultation class="software-company" v-scroll-reveal/>
      <ExploreMore  v-scroll-reveal/>
      <ExploreMoreText class="software-company" v-scroll-reveal/>
      <WebDevelopment class="software-company" v-scroll-reveal/>
      <MobileApp class="software-company" v-scroll-reveal/>
      <ContacDarshan class="software-company" v-scroll-reveal/>
      <DummyContainer />
    </span>
  </div>
</template>

<script>
import ScrollReveal from 'scrollreveal';
import ContacDarshan from '@/IOTDevelopmentComponents/ContacDarshan.vue';
import About_text from '@/IOTDevelopmentComponents/About_text.vue';
import WebImage from '@/IOTDevelopmentComponents/WebImage.vue';
import AboutPara from '@/IOTDevelopmentComponents/AboutPara.vue';
import TopWebDesign from '@/IOTDevelopmentComponents/TopWebDesign.vue';
import TopWebDesignText from '@/IOTDevelopmentComponents/TopWebDesignText.vue';
import QualityThat from '@/IOTDevelopmentComponents/QualityThat.vue';
import QualityText from '@/IOTDevelopmentComponents/QualityText.vue';
import SplitContainer from '@/IOTDevelopmentComponents/SplitContainer.vue';
import WebDevTeam from '@/IOTDevelopmentComponents/WebDevTeam.vue';
import MeetWebDevs from '@/IOTDevelopmentComponents/MeetWebDevs.vue';
import ThreeSection from '@/IOTDevelopmentComponents/ThreeSection.vue';
import FreeConsultation from '@/IOTDevelopmentComponents/FreeConsultation.vue';
import IOTHeading from '@/IOTDevelopmentComponents/IOTHeading.vue';
import IOTText from '@/IOTDevelopmentComponents/IOTText.vue';
import ExploreMore from '@/IOTDevelopmentComponents/ExploreMore.vue';
import ExploreMoreText from '@/IOTDevelopmentComponents/ExploreMoreText.vue';
import DummyContainer from '@/IOTDevelopmentComponents/DummyContainer.vue';
import WebDevelopment from '@/components/WebDevelopment.vue';
import MobileApp from '@/components/MobileApp.vue';
import { useHead } from '@unhead/vue';


export default {
  name: 'IOTDevelopment',
  components: {
    MobileApp,
    IOTHeading,
    IOTText,
    WebDevelopment,
    About_text,
    WebImage,
    AboutPara,
    TopWebDesign,
    TopWebDesignText,
    QualityThat,
    QualityText,
    SplitContainer,
    WebDevTeam,
    MeetWebDevs,
    ThreeSection,
    ContacDarshan,
    FreeConsultation,
    ExploreMore,
    ExploreMoreText,
    DummyContainer,
  },
  setup(){
    useHead({
    title: 'IoT Development Services - Vuega',
    meta: [
    { name: 'description', content: 'Vuega offers comprehensive IoT development services. Connect your devices and systems to create smart, data-driven solutions for your business.' },
    { name: 'keywords', content: 'vuega hardware, darshan ra, IoT development, Internet of Things, smart devices, IoT solutions, connected devices, IoT services' },
    { property: 'og:title', content: 'IoT Development Services - Vuega' },
    { property: 'og:description', content: 'Transform your business with Vuega’s IoT development services. We build scalable and secure IoT solutions that connect your devices and systems.' },
    { property: 'og:type', content: 'website' },
      ]
    });
  },
  mounted() {
    const sr = ScrollReveal({
      origin: 'bottom',
      distance: '100px',
      duration: 1500,
      delay: 400,
      reset: false,
      viewFactor: 0.1,
    });

    sr.reveal('.container');
    sr.reveal('.software-company', { interval: 300 });
    sr.reveal('.software-company > *', { interval: 300 });

    sr.reveal('.from-left', {
      origin: 'left',
      distance: '400px',
    });

    sr.reveal('.from-right', {
      origin: 'right',
      distance: '400px',
    });
  },
};
</script>

<style scoped>
.container {
  height: 600px;
  max-width: 75%;
  background-color: white; /* Change to your desired background color */
  padding: 10px 15px; /* Padding around the container */
  border-radius: 6px; /* Optional: rounded corners */
  margin: 0 auto; /* Center horizontally */
  margin-top: 180px;
}

.cn-1 {
  display: flex;
  justify-content: space-between; /* Pushes items to the edges */
  align-items: flex-start; /* Align items to the top */
}

.text-container {
  margin-top: 50px;
  display: flex;
  flex-direction: column; /* Stack children vertically */
}

.about-text {
  margin-top: 0; /* Adjust this value to move AboutText higher or lower */
}

.para-text {
  margin-top: 35px; /* Adjust the space between AboutText and Para */
}

.working-image {
  align-self: flex-end;
}

/* Optional: add custom styles for the reveal animations */
.from-left {
  visibility: hidden; /* Ensure elements are initially hidden */
}

.from-right {
  visibility: hidden; /* Ensure elements are initially hidden */
}
</style>
