<template>
    <div>
    <div class="soft-cmp">
        
        <p>Our professionals have considerable expertise in developing different types of apps for diverse enterprises worldwide. Work with top-tier mobile app developers, Project Managers, UI/UX designers, QA testers, and other specialists, to produce a scalable custom mobile application tailored to your company’s standards and market requirements.</p>

    </div>

    
</div>
  </template>
  
  <script>
  export default {
    name: 'BestCompanyText'
  }
  </script>
  
  <style scoped>
  .soft-cmp {
    align-content: flex-start;
    justify-content: start;
    text-align: start;
    background-color: white; /* Background color */
    padding: 20px 20px 20px 0; /* Padding: Top, Right, Bottom, Left */
    border-radius: 8px; /* Rounded corners */
    text-align: start; /* Text alignment */
    margin: 20px auto; /* Center the container and add top/bottom margin */
    max-width: 75%; /* Limit the max width */
    position: relative;
    max-height: 200px;
  }
  
  p {
    font-family: "Montserrat", sans-serif;
    line-height: 28px;
    padding: 23px 0 0;
    font-size: 16px !important;
    font-weight: 400 !important;
    margin-top: 0;/* Remove default margin to eliminate space between headings */
    
  }
  </style>
  