<template>
  <div>
    <div class="container" v-scroll-reveal>
      <div class="cn-1">
        <div class="text-container">
          <About_text class="about-text" />
          <AboutPara class="para-text" />
        </div>
        <WebImage class="software-company" v-scroll-reveal/>
      </div>
    </div>
    <span class="software-company" v-scroll-reveal>
      <TopWebDesign class="software-company" v-scroll-reveal/>
      <TopWebDesignText class="software-company" v-scroll-reveal/>
      <WebDevTeam class="software-company" v-scroll-reveal/>
      <MeetWebDevs class="software-company" v-scroll-reveal/>
      <BestCompany v-scroll-reveal/>
      <BestCompanyText  v-scroll-reveal/>
      <BestCompanyPage class="software-company" v-scroll-reveal/>
      <FreeConsultation class="software-company" v-scroll-reveal/>
      <WebNutshell class="software-company" v-scroll-reveal/>
      <ConsultantHeading  v-scroll-reveal/>
      <Consultant v-scroll-reveal/>
      <ExploreMore class="software-company" v-scroll-reveal/>
      <ExploreMoreText class="software-company" v-scroll-reveal/>
      <MobileApp class="software-company" v-scroll-reveal/>
      <ProductDesign class="software-company" v-scroll-reveal/>
      <DummyContainer />
    </span>
  </div>
</template>

<script>
import ScrollReveal from 'scrollreveal';
import About_text from '@/WebDevelopmentComponents/About_text.vue';
import WebImage from '@/WebDevelopmentComponents/WebImage.vue';
import AboutPara from '@/WebDevelopmentComponents/AboutPara.vue';
import TopWebDesign from '@/WebDevelopmentComponents/TopWebDesign.vue';
import TopWebDesignText from '@/WebDevelopmentComponents/TopWebDesignText.vue';
import WebDevTeam from '@/WebDevelopmentComponents/WebDevTeam.vue';
import MeetWebDevs from '@/WebDevelopmentComponents/MeetWebDevs.vue';
import BestCompany from '@/WebDevelopmentComponents/BestCompany.vue';
import BestCompanyText from '@/WebDevelopmentComponents/BestCompanyText.vue';
import BestCompanyPage from '@/WebDevelopmentComponents/BestCompanyPage.vue';
import FreeConsultation from '@/WebDevelopmentComponents/FreeConsultation.vue';
import WebNutshell from '@/WebDevelopmentComponents/WebNutshell.vue';
import ConsultantHeading from '@/WebDevelopmentComponents/ConsultantHeading.vue';
import Consultant from '@/WebDevelopmentComponents/Consultant.vue';
import ExploreMore from '@/WebDevelopmentComponents/ExploreMore.vue';
import ExploreMoreText from '@/WebDevelopmentComponents/ExploreMoreText.vue';
import MobileApp from '@/components/MobileApp.vue';
import ProductDesign from '@/components/ProductDesign.vue';
import DummyContainer from '@/WebDevelopmentComponents/DummyContainer.vue';
import { useHead } from '@unhead/vue';



export default {
  name: 'WebDevelopmentPage',
  components: {
    About_text,
    WebImage,
    AboutPara,
    TopWebDesign,
    TopWebDesignText,
    WebDevTeam,
    MeetWebDevs,
    BestCompany,
    BestCompanyText,
    BestCompanyPage,
    FreeConsultation,
    WebNutshell,
    ConsultantHeading,
    Consultant,
    ExploreMore,
    ExploreMoreText,
    MobileApp,
    ProductDesign,
    DummyContainer,
  },
  setup(){
    useHead({
      title: 'AI Development Services - Vuega',
      meta: [
        { name: 'description', content: 'Explore cutting-edge AI development services with Vuega. From machine learning models to intelligent automation, we help businesses harness the power of AI.' },
        { name: 'keywords', content: 'Vuega, Vuega AI, VUEGAai, GenAI, startups, GenAI startups, AI development, machine learning, artificial intelligence, AI services, intelligent automation' },
        { property: 'og:title', content: 'AI Development Services - Vuega' },
        { property: 'og:description', content: 'Discover how Vuega’s AI development services can transform your business. Our expertise in AI can drive innovation and efficiency.' },
        { property: 'og:type', content: 'website' },
      ]
    });
  },
  mounted() {
    const sr = ScrollReveal({
      origin: 'bottom',
      distance: '50px',
      duration: 1000,
      delay: 200,
      reset: false,
      viewFactor: 0.1,
    });

    sr.reveal('.container', { interval: 200 });
    sr.reveal('.software-company', { interval: 300 });
    sr.reveal('.software-company > *', { interval: 300 });
  },
};
</script>

<style scoped>
.container {
  height: 600px;
  max-width: 75%;
  background-color: white; /* Change to your desired background color */
  padding: 10px 15px; /* Padding around the container */
  border-radius: 6px; /* Optional: rounded corners */
  margin: 0 auto; /* Center horizontally */
  margin-top: 180px;
}

.cn-1 {
  display: flex;
  justify-content: space-between; /* Pushes items to the edges */
  align-items: flex-start; /* Align items to the top */
}

.text-container {
  margin-top: 50px;
  display: flex;
  flex-direction: column; /* Stack children vertically */
}

.about-text {
  margin-top: 0; /* Adjust this value to move AboutText higher or lower */
}

.para-text {
  margin-top: 35px; /* Adjust the space between AboutText and Para */
}

.working-image {
  align-self: flex-end;
}
</style>
