<template>
  <div>
    <div class="container" v-scroll-reveal>
      <div class="cn-1">
        <div class="text-container">
          <About_text class="about-text" />
          <AboutPara class="para-text" />
        </div>
        <WebImage class="software-company" v-scroll-reveal/>
      </div>
    </div>
    <span class="software-company" v-scroll-reveal>
      <TopWebDesign />
      <TopWebDesignText />
      <WebDevTeam class="software-company" v-scroll-reveal/>
      <MeetWebDevs class="software-company" v-scroll-reveal/>
      <BestCompany class="software-company" v-scroll-reveal/>
      <BestCompanyText class="software-company" v-scroll-reveal/>
      <IosDevelopment class="software-company" v-scroll-reveal/>
      <FlutterDevelopment class="software-company" v-scroll-reveal/>
      <WebNutshell class="software-company" v-scroll-reveal/>
      <FreeConsultation  v-scroll-reveal/>
      <ExploreMore class="software-company" v-scroll-reveal/>
      <ExploreMoreText class="software-company" v-scroll-reveal />
      <WebDevelopment class="software-company" v-scroll-reveal/>
      <DummyContainer />
    </span>
  </div>
</template>

<script>
import ScrollReveal from 'scrollreveal';
import About_text from '@/MobileDevelopmentComponents/About_text.vue';
import WebImage from '@/MobileDevelopmentComponents/WebImage.vue';
import AboutPara from '@/MobileDevelopmentComponents/AboutPara.vue';
import TopWebDesign from '@/MobileDevelopmentComponents/TopWebDesign.vue';
import TopWebDesignText from '@/MobileDevelopmentComponents/TopWebDesignText.vue';
import WebDevTeam from '@/MobileDevelopmentComponents/WebDevTeam.vue';
import MeetWebDevs from '@/MobileDevelopmentComponents/MeetWebDevs.vue';
import BestCompany from '@/MobileDevelopmentComponents/BestCompany.vue';
import BestCompanyText from '@/MobileDevelopmentComponents/BestCompanyText.vue';
import IosDevelopment from '@/MobileDevelopmentComponents/IosDevelopment.vue';
import FlutterDevelopment from '@/MobileDevelopmentComponents/FlutterDevelopment.vue';
import FreeConsultation from '@/MobileDevelopmentComponents/FreeConsultation.vue';
import WebNutshell from '@/MobileDevelopmentComponents/WebNutshell.vue';
import ExploreMore from '@/MobileDevelopmentComponents/ExploreMore.vue';
import ExploreMoreText from '@/MobileDevelopmentComponents/ExploreMoreText.vue';
import DummyContainer from '@/MobileDevelopmentComponents/DummyContainer.vue';
import WebDevelopment from '@/components/WebDevelopment.vue';
import { useHead } from '@unhead/vue';


export default {
  name: 'MobileDevelopment',
  components: {
    WebDevelopment,
    About_text,
    WebImage,
    AboutPara,
    TopWebDesign,
    TopWebDesignText,
    WebDevTeam,
    MeetWebDevs,
    BestCompany,
    BestCompanyText,
    IosDevelopment,
    FlutterDevelopment,
    WebNutshell,
    FreeConsultation,
    ExploreMore,
    ExploreMoreText,
    DummyContainer,
  },
  setup() {
    useHead({
  title: 'Mobile App Development Services - Vuega',
  meta: [
    { name: 'description', content: 'Vuega provides end-to-end mobile app development services. Create intuitive and feature-rich mobile applications for iOS and Android platforms.' },
    { name: 'keywords', content: 'Vuega, VuegaAI, startups, vuega app, mobile app development, iOS development, Android development, mobile apps, mobile solutions' },
    { property: 'og:title', content: 'Mobile App Development Services - Vuega' },
    { property: 'og:description', content: 'Develop robust mobile apps with Vuega. Our mobile development services are tailored to meet the needs of your business and users.' },
    { property: 'og:type', content: 'website' },
  ]
});
  },
  mounted() {
    const sr = ScrollReveal({
      origin: 'bottom',
      distance: '100px',
      duration: 1500,
      delay: 400,
      reset: false,
      viewFactor: 0.1,
    });

    sr.reveal('.container');
    sr.reveal('.software-company', { interval: 300 });
    sr.reveal('.software-company > *', { interval: 300 });

    sr.reveal('.from-left', {
      origin: 'left',
      distance: '400px',
    });

    sr.reveal('.from-right', {
      origin: 'right',
      distance: '400px',
    });
  },
};
</script>

<style scoped>
.container {
  height: 600px;
  max-width: 75%;
  background-color: white; /* Change to your desired background color */
  padding: 10px 15px; /* Padding around the container */
  border-radius: 6px; /* Optional: rounded corners */
  margin: 0 auto; /* Center horizontally */
  margin-top: 180px;
}

.cn-1 {
  display: flex;
  justify-content: space-between; /* Pushes items to the edges */
  align-items: flex-start; /* Align items to the top */
}

.text-container {
  margin-top: 50px;
  display: flex;
  flex-direction: column; /* Stack children vertically */
}

.about-text {
  margin-top: 0; /* Adjust this value to move AboutText higher or lower */
}

.para-text {
  margin-top: 35px; /* Adjust the space between AboutText and Para */
}

.working-image {
  align-self: flex-end;
}

/* Optional: add custom styles for the reveal animations */
.from-left {
  visibility: hidden; /* Ensure elements are initially hidden */
}

.from-right {
  visibility: hidden; /* Ensure elements are initially hidden */
}
</style>
