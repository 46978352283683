<template>
  <div class="flipbook-container">
    <iframe
      src="https://www.yumpu.com/xx/embed/view/gJwxtG8QjS9z6ckq"
      width="100%"
      height="100%"
      frameborder="0"
      allowfullscreen
    ></iframe>
  </div>
</template>
<script>
export default {
  name: 'OurProducts',
}
</script>

<style scoped>
.flipbook-container {
  width: 100%;
  height: 92vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f8f8f8; 
  padding-top: 80px; /* Optional: Customize background color */
}
</style>
