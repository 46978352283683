<template>
  <div class="mobile-app-container">
    <div class="left-side">
      <!-- <h1>Mobile App Development</h1> -->
      <p>Our Technical Team consultant will have a detailed conversation with you to understand your needs. They'll provide an accurate estimation, addressing all your queries thoroughly. This ensures you have the clarity and confidence to move forward with your project.</p>
      <a href="https://docs.google.com/forms/d/e/1FAIpQLSfoKVND-WJkwXQSpqVHsiwwCUQes3HynfRafTpmo-zy9REbug/viewform" target="_blank" rel="noopener noreferrer" class="learn-more-link">
        <div class="learn-more">
          <span>Learn More</span>
          <Icon icon="uil:arrow-right" width="20" color="#182eff" />
        </div>
      </a>
    </div>
    <div class="right-side">
      <img src="@/assets/darshan_shape.png" alt="Mobile App" />
    </div>
  </div>
</template>

<script>
import { Icon } from '@iconify/vue';

export default {
  name: 'MobileApp',
  components: {
    Icon
  }
}
</script>

<style scoped>
.mobile-app-container {
  display: flex;
  justify-content: space-between; /* Pushes items to the edges */
  align-items: center; /* Vertically center items */
  max-width: 75%;
  margin: 20px auto 0; /* Add top margin for spacing */
  padding: 20px;
  background-color: #f6fbfe; /* Background color */
  border-radius: 8px; /* Rounded corners */
  transition: all 0.3s ease; /* Smooth transition for hover effects */
  margin-top: 30px;
  height: 500px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Default shadow */
}

.mobile-app-container:hover {
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2); /* Shadow on hover */
}

.left-side {
  display: flex;
  flex-direction: column;
  justify-content: center; /* Vertically center content */
  max-width: 50%; /* Limit the width of the left side */
  padding-left: 20px; /* Add space between the left side and the text */
}

.left-side h1 {
  font-family: "Montserrat", sans-serif;
  font-size: 36px;
  font-weight: 700;
  margin: 0 0 10px; /* Space below the heading */
  color: black; /* Default heading color */
}

.left-side p {
  font-family: "Montserrat", sans-serif;
  font-size: 16px !important;
  line-height: 28px !important;
  padding: 23px 0 0 0;
  color: black; /* Ensure text color is consistent */
}

.learn-more-link {
  text-decoration: none; /* Remove underline from link */
}

.learn-more {
  display: flex;
  align-items: center;
  margin-top: 20px;
  cursor: pointer;
}

.learn-more span {
  font-family: "Montserrat", sans-serif;
  font-size: 16px;
  font-weight: bold;
  color: #182eff; /* Text color */
  margin-right: 10px; /* Space between text and icon */
}

.learn-more .iconify {
  font-size: 20px; /* Icon size */
  transition: transform 0.3s ease; /* Smooth transition for icon movement */
}

.learn-more-link:hover .learn-more .iconify {
  transform: translateX(5px); /* Move icon slightly to the right on hover */
}

.right-side img {
  max-width: 100%; /* Ensure image scales responsively */
  width: 445px; /* Set the width to 445 pixels */
  height: 100%;
  border-radius: 8px; /* Optional: rounded corners for the image */
}
</style>
