<template>
    <div class="main-container">
      <div class="right-part">
        <img src="@/assets/sece_logo.png" alt="Image" class="right-image">
      </div>
      <div class="left-part">
        <h1>Our Workplace!</h1>
        <p>At Sri Eshwar College of Engineering, our workplace fosters a culture of innovation and collaboration. With state-of-the-art facilities and a supportive environment, we empower students and professionals to excel in their technical pursuits and contribute to cutting-edge projects.</p>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    name: 'WorkPlace',
    components: {}
  }
  </script>
  
  <style scoped>
  .main-container {
    margin-top: 50px;
    width: 100%;
    height: 600px;
    display: flex;
    box-sizing: border-box;
    background-color: white;
    flex-direction: row-reverse; /* Reverses the order of the flex items */
  }
  
  .right-part {
    width: 50%; /* Half of the container width */
    height: 100%;
    display: flex;
    justify-content: center; /* Center the image horizontally */
    align-items: center; /* Center the image vertically */
    padding: 20px; /* Optional: add some padding */
    box-sizing: border-box;
  }
  
  .right-image {
    width: 550px;
    height: 460px;
    border-radius: 15px; /* Rounded corners */
    object-fit: contain; /* Ensure the image covers the area */
  }
  
  .left-part {
    width: 50%; /* Half of the container width */
    height: 100%;
    padding: 20px; /* Add padding around the content */
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: center; /* Center content vertically */
  }
  
  h1 {
    font-family: "Montserrat", sans-serif;
    font-size: 36px;
    font-weight: bold;
    color: #333;
    margin: 0 0 20px; /* Margin around the heading */
    padding-left: 200px;
  }
  
  p {
    font-family: "Montserrat", sans-serif;
    font-size: 18px;
    color: #555;
    line-height: 36px;
    margin: 0; /* Remove margin around the paragraph */
    padding-left: 200px; /* Optional: adjust padding to fit your design */
  }
  </style>
  