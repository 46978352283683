<template>
  <footer class="footer">
    <div class="footer-container">
      <div class="footer-section">
        <h4>Quick Links</h4>
        <ul>
          <li><router-link to="/">Home</router-link></li>
          <li><router-link to="/services">Services</router-link></li>
          <li><router-link to="/about-us">About Us</router-link></li>
          <li><a href="https://forms.gle/UwUgknFtMZmaoidC7" target="_blank" rel="noopener noreferrer">Contact</a></li>
        </ul>
      </div>
      <div class="footer-section">
        <h4>Contact Us</h4>
        <p>Email: contact.vuega@gmail.com</p>
        <p>Phone: +91 94892 41506, +91 9025829115</p>
        <p>Address: Kinathukadavu, Coimbatore, India</p>
      </div>
      <div class="footer-section">
        <h4>Follow Us</h4>
        <div class="social-icons">
          <a href="https://www.linkedin.com/company/vuega-ai/about/" target="_blank" rel="noopener noreferrer">
            <Icon icon="skill-icons:linkedin" class="social-icon" />
          </a>
          <a href="https://www.facebook.com" target="_blank" rel="noopener noreferrer">
            <Icon icon="logos:facebook" class="social-icon" />
          </a>
          <a href="https://www.twitter.com" target="_blank" rel="noopener noreferrer">
            <Icon icon="devicon:twitter" height="30"/>
          </a>
          <a href="https://wa.me/+919095293285" target="_blank">
            <Icon icon="logos:whatsapp-icon" class="social-icon" />
          </a>
        </div>
      </div>
    </div>
    <div class="footer-bottom">
      <p>&copy; 2024 VUEGA. All Rights Reserved.</p>
    </div>
  </footer>
</template>

<script>
import { Icon } from '@iconify/vue';

export default {
  name: "FooterComponent",
  components: {
    Icon
  }
};
</script>

<style scoped>
.footer {
  background-color: #f6fbfe;
  color: blue;
  padding: 20px 0;
  text-align: center;
}

.footer-container {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
}

.footer-section {
  flex: 1;
  min-width: 200px;
  margin: 10px;
}

.footer-section h4 {
  font-family: "Montserrat", sans-serif;
  font-size: 16px;
  font-weight: bold;
  color: #0c0042; /* Text color */
  margin-bottom: 15px;
}

.footer-section p, .footer-section ul li a {
  font-family: "Montserrat", sans-serif;
  font-size: 14px;
  font-weight: normal;
  color: #0c0042; /* Text color */
  margin-right: 10px; /* Space between text and icon */
}

.footer-section ul {
  list-style: none;
  padding: 0;
}

.footer-section ul li {
  margin-bottom: 10px;
}

.footer-section ul li a {
  text-decoration: none;
  transition: all .4s ease; /* Transition for smooth movement */
}

.footer-section ul li a:hover {
  margin-left: 20px; /* Move to the right on hover */
  color: #00afff;
}

.social-icons {
  display: flex;
  justify-content: center;
  gap: 15px;
}

.social-icon {
  font-size: 32px;
  color: black;
  filter: grayscale(100%); /* Make the icon black and white */
  transition: filter 0.3s ease, color 0.3s ease;
}

.social-icon:hover {
  filter: grayscale(0%); /* Make the icon colored */
  color: initial; /* Reset color on hover */
}

.footer-bottom {
  font-family: "Montserrat", sans-serif;
  font-size: 16px;
  font-weight: 600;
  color: #0c0042; /* Text color */
  margin-top: 20px;
  border-top: 1px solid #444;
  padding-top: 10px;
}
</style>
