<template>
  <div class="heading-container">
    <h1>VUEGA Careers</h1>
    <p>Are you passionate about technology and innovation? At Vuega, we're always on the lookout for talented individuals to join our dynamic team. Whether you're a seasoned developer, a creative designer, or a strategic thinker, we offer exciting career opportunities to help you grow and succeed. Be part of a forward-thinking company where your skills and ideas are valued. Apply now and take your career to the next level with us!</p>
  </div>
</template>

<script>
export default {
  name: 'AboutCareer',
  components: {

  }
}
</script>

<style scoped>


.heading-container {
  max-width: 75%;
  height: 450px; /* Set to the desired height */
  margin-bottom: 0px;
 
}

h1 {
  font-family: "Kanit", sans-serif;
  font-weight: bold;
  color: #333;
  font-size: 70px;
  margin: 0; /* Remove default margin to eliminate space between headings */
  text-align: left; /* Align text to the left */
}

p {
    font-family: "Montserrat", sans-serif;
    font-weight: 400 !important;
    line-height: 25px !important;
    margin: 0; /* Remove default margin to eliminate space between headings */
    font-size: 18px;  
    text-align: center;
    margin-top: 30px;
    margin-bottom: 0px;
    line-height: 30px;

  }
</style>
