<template>
    <div class="contaienr">
        <SplitContainer class="software-company" v-scroll-reveal/>
        <TeamDetails class="software-company" v-scroll-reveal/>
        <TeamDetailsText class="software-company" v-scroll-reveal/>
        <TeamGrid class="software-company" v-scroll-reveal/>
        <SupportersHeading class="software-company" v-scroll-reveal/>
        <SupportersText class="software-company" v-scroll-reveal/>
        <Supprters class="software-company" v-scroll-reveal/>
        <ExploreMore class="software-company" v-scroll-reveal/>
        <ExploreMoreText class="software-company" v-scroll-reveal/>
        <WebDevelopment class="software-company" v-scroll-reveal/>
        <MobileApp class="software-company" v-scroll-reveal/>
    </div>
  
    
</template>


<script>
import ScrollReveal from 'scrollreveal';
import SplitContainer from '@/AboutUsComponents/SplitContainer.vue';
import TeamGrid from '@/AboutUsComponents/TeamGrid.vue';
import TeamDetails from '@/AboutUsComponents/TeamDetails.vue';
import TeamDetailsText from '@/AboutUsComponents/TeamDetailsText.vue';
import SupportersHeading from '@/AboutUsComponents/SupportersHeading.vue';
import SupportersText from '@/AboutUsComponents/SupportersText.vue';
import Supprters from '@/AboutUsComponents/Supprters.vue';
import ExploreMore from '@/AIDevelopmentComponents/ExploreMore.vue';
import ExploreMoreText from '@/WebDevelopmentComponents/ExploreMoreText.vue';
import WebDevelopment from '@/components/WebDevelopment.vue';
import MobileApp from '@/components/MobileApp.vue';
import { useHead } from '@unhead/vue';

useHead({
  title: 'About Us - Vuega',
  meta: [
    {
      name: 'description',
      content: 'Learn more about Vuega, our mission, vision, and the team behind our success.',
    },
    {
      name: 'keywords',
      content: 'Vuega, About Vuega, AI company, web development company, mobile app development',
    },
    {
      property: 'og:title',
      content: 'About Us - Vuega',
    },
    {
      property: 'og:description',
      content: 'Discover Vuega’s journey and the core values that drive us.',
    },
    {
      property: 'og:type',
      content: 'website',
    },
  ],
});

export default {
    name : 'AboutUs',
    components :{
        SplitContainer,
        TeamDetails,
        TeamDetailsText,
        TeamGrid,
        SupportersHeading,
        SupportersText,
        Supprters,
        ExploreMore,
        ExploreMoreText,
        WebDevelopment,
        MobileApp,
        
    },
    mounted() {
    const sr = ScrollReveal({
      origin: 'bottom',
      distance: '100px',
      duration: 1500,
      delay: 400,
      reset: false,
      viewFactor: 0.1,
    });

    sr.reveal('.container');
    sr.reveal('.software-company', { interval: 300 });
    sr.reveal('.software-company > *', { interval: 300 });

    sr.reveal('.from-left', {
      origin: 'left',
      distance: '400px',
    });

    sr.reveal('.from-right', {
      origin: 'right',
      distance: '400px',
    });
  },
}
</script>

<style scoped>
.container {
  height: 600px;
  max-width: 75%;
  background-color: white; /* Change to your desired background color */
  padding: 10px 15px; /* Padding around the container */
  border-radius: 6px; /* Optional: rounded corners */
  margin: 0 auto; /* Center horizontally */
  margin-top: 180px;
}

</style>