<template>
    <div>
    <div class="soft-cmp">
        
        <p>As a dedicated IoT solutions provider, we profoundly care for our clients, treating their business objectives as our own. That’s why we invest significant energy to evaluate and bring your concept to life. Learn why you should partner with us on your IoT project.</p>

    </div>

    
</div>
  </template>
  
  <script>
  export default {
    name: 'QualityText'
  }
  </script>
  
  <style scoped>
  .soft-cmp {
    align-content: flex-start;
    justify-content: start;
    text-align: start;
    background-color: white; /* Background color */
    padding: 20px 20px 20px 0; /* Padding: Top, Right, Bottom, Left */
    border-radius: 8px; /* Rounded corners */
    text-align: start; /* Text alignment */
    margin: 20px auto; /* Center the container and add top/bottom margin */
    max-width: 75%; /* Limit the max width */
    position: relative;
    max-height: 200px;
  }
  
  p {
    font-family: "Montserrat", sans-serif;
    line-height: 28px;
    padding: 23px 0 0;
    font-size: 16px !important;
    font-weight: 400 !important;
    margin-top: 0;/* Remove default margin to eliminate space between headings */
    
  }
  </style>
  