<template>
    <div class="meet-the-team">
      <div class="team-container">
        <div class="team-member">
          <img src="@/assets/kayal.jpg" alt="Team Member 1" />
          <div class="info">
            <h3>Kayal Ennian A G</h3>
            <h4>Frontend Lead</h4>
            <div class="social-icons">
              <a href="https://www.linkedin.com/in/kayal-ennian-a-g-80b515245/" target="_blank">
                <Icon icon="skill-icons:linkedin" height="24" />
              </a>
              <a href="https://wa.me/+919025829115" target="_blank">
                <Icon icon="logos:whatsapp-icon" height="24" />
              </a>
            </div>
          </div>
        </div>
        <div class="team-member">
          <img src="@/assets/Vishnu_profile.jpeg" alt="Team Member 2" />
          <div class="info">
            <h3>Vishnu Ram M</h3>
            <h4>Backend Lead</h4>
            <div class="social-icons">
              <a href="https://www.linkedin.com/in/vrdev/" target="_blank">
                <Icon icon="skill-icons:linkedin" height="24" />
              </a>
              <a href="https://wa.me/+919283200200" target="_blank">
                <Icon icon="logos:whatsapp-icon" height="24" />
              </a>
            </div>
          </div>
        </div>
        <div class="team-member">
          <img src="@/assets/dummy-profile-pic-1.jpg" alt="Team Member 3" />
          <div class="info">
            <h3>Name 3</h3>
            <h4>Position 3</h4>
            <div class="social-icons">
              <a href="https://www.linkedin.com" target="_blank">
                <Icon icon="skill-icons:linkedin" height="24" />
              </a>
              <a href="https://wa.me/your-whatsapp-number" target="_blank">
                <Icon icon="logos:whatsapp-icon" height="24" />
              </a>
            </div>
          </div>
        </div>
        <div class="team-member">
          <img src="@/assets/dummy-profile-pic-1.jpg" alt="Team Member 4" />
          <div class="info">
            <h3>Name 4</h3>
            <h4>Position 4</h4>
            <div class="social-icons">
              <a href="https://www.linkedin.com" target="_blank">
                <Icon icon="skill-icons:linkedin" height="24" />
              </a>
              <a href="https://wa.me/your-whatsapp-number" target="_blank">
                <Icon icon="logos:whatsapp-icon" height="24" />
              </a>
            </div>
          </div>
        </div>
        <div class="team-member">
          <img src="@/assets/dummy-profile-pic-1.jpg" alt="Team Member 5" />
          <div class="info">
            <h3>Name 5</h3>
            <h4>Position 5</h4>
            <div class="social-icons">
              <a href="https://www.linkedin.com" target="_blank">
                <Icon icon="skill-icons:linkedin" height="24" />
              </a>
              <a href="https://wa.me/your-whatsapp-number" target="_blank">
                <Icon icon="logos:whatsapp-icon" height="24" />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import { Icon } from '@iconify/vue';
  
  export default {
    name: "MeetWebDevs",
    components: {
      Icon
    }
  };
  </script>
  
  <style scoped>
  .meet-the-team {
    width: 75%;
    margin: 0 auto; /* Center the container */
    overflow-x: auto; /* Scrollable container */
    padding: 20px 0;
  }
  
  .team-container {
    display: flex;
    flex-direction: row;
    gap: 20px;
  }
  
  .team-member {
    position: relative;
    width: 375px;
    height: 290px;
    border-radius: 10px;
    overflow: hidden;
    transition: transform 0.3s ease;
  }
  
  .team-member:hover {
    transform: scale(1.05); /* Slight zoom on hover */
  }
  
  .team-member img {
    width: 100%;
    height: 100%;
    object-fit: cover;
   
  }
  
  .info {
    position: absolute;
    bottom: -17%; /* Hide initially */
    left: 0;
    width: 100%;
    padding: 20px;
    background: rgba(0, 0, 0, 0.7); /* Faded area */
    color: #fff;
    transition: bottom 0.3s ease; /* Transition for expanding */
  }
  
  .team-member:hover .info {
    bottom: 0; /* Show the info on hover */
  }
  
  .social-icons {
    display: flex;
    gap: 15px;
    margin-top: 10px;
  }
  
  .social-icons a {
    color: #fff; /* Set color for the icons */
    text-decoration: none; /* Remove underline from links */
  }
  
  .info h3 {
    margin: 0;
    font-family: "Montserrat", sans-serif;
    font-size: 18px;
    font-weight: bold;
  }
  
  .info h4 {
    margin: 0;
    font-family: "Montserrat", sans-serif;
    font-size: 16px;
    font-weight: normal;
  }
  </style>
  