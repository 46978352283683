<template>
  <meta name="description" content="Welcome to Vuega - We provide AI solutions, web development, and mobile app development services.">
  <div>
    <div class="container" v-scroll-reveal>
      <div class="cn-1">
        <div class="text-container">
          <AboutText class="about-text" />
          <Para class="para-text" />
        </div>
        <WorkingImage />
      </div>
    </div>
    
    <div class="software-company" v-scroll-reveal>
      <SoftwareCompany/>
      <SoftwareText/>
      <WebDevelopment/>
      <MobileApp/>
      <ProductDesign/>
    </div>

    <div class="software-company" v-scroll-reveal>
      <IndustriesHeading/>
      <IndustriesText/>
      <IGamingDevelopment/>
      <!-- <SportsBetting/> -->
      <!-- <CasinoSoftware/> -->
      <FinTech/>
      <BlockchainDevelopment/>
    </div>

    <div class="software-company" v-scroll-reveal>
      <CooperationModel/>
      <CooperationModelText/>
      <ProductDevelopment/>
      <StaffAugmentation/>
    </div>

    <div class="software-company" v-scroll-reveal>
      <ClientValueUs/>
      <ClientValueUsText/>
      <ClientValueUsPage/>
    </div>

    <div class="software-company" v-scroll-reveal>
      <ReadmoreAboutUs/>
      <WeVuega/>
    </div>

    <div class="software-company" v-scroll-reveal>
      <StartWorkingWithUs/>
      <StartWorkingWithUsText/>
      <StartWithUsTimeline/>
      
    </div>

    <div class="software-company" v-scroll-reveal>
      <ContactUs/>
    </div>
  </div>
</template>

<script>
import ScrollReveal from 'scrollreveal';
import AboutText from '@/components/AboutText.vue';
import WorkingImage from '@/components/WorkingImage.vue';
import Para from '@/components/Para.vue';
import SoftwareCompany from '@/components/SoftwareCompany.vue';
import SoftwareText from '@/components/SoftwareText.vue';
import WebDevelopment from '@/components/WebDevelopment.vue';
import MobileApp from '@/components/MobileApp.vue';
import ProductDesign from '@/components/ProductDesign.vue';
import IndustriesHeading from '@/components/IndustriesHeading.vue';
import IndustriesText from '@/components/IndustriesText.vue';
import IGamingDevelopment from '@/components/IGamingDevelopment.vue';
// import SportsBetting from '@/components/SportsBetting.vue';
// import CasinoSoftware from '@/components/CasinoSoftware.vue';
import FinTech from '@/components/FinTech.vue';
import BlockchainDevelopment from '@/components/BlockchainDevelopment.vue';
import CooperationModel from '@/components/CooperationModel.vue';
import CooperationModelText from '@/components/CooperationModelText.vue';
import ProductDevelopment from '@/components/ProductDevelopment.vue';
import StaffAugmentation from '@/components/StaffAugmentation.vue';
import ClientValueUs from '@/components/ClientValueUs.vue';
import ClientValueUsText from '@/components/ClientValueUsText.vue';
import ClientValueUsPage from '@/components/ClientValueUsPage.vue';
import ReadmoreAboutUs from '@/components/ReadmoreAboutUs.vue';
import WeVuega from '@/components/WeVuega.vue';
import StartWorkingWithUs from '@/components/StartWorkingWithUs.vue';
import StartWorkingWithUsText from '@/components/StartWorkingWithUsText.vue';
import StartWithUsTimeline from '@/components/StartWithUsTimeline.vue';
import ContactUs from '@/components/ContactUs.vue';
import { useHead } from '@unhead/vue';


export default {
  name: 'YourCompany',
  components: {
    AboutText,
    WorkingImage,
    Para,
    SoftwareCompany,
    SoftwareText,
    WebDevelopment,
    MobileApp,
    ProductDesign,
    IndustriesHeading,
    IndustriesText,
    IGamingDevelopment,
    // SportsBetting,
    // CasinoSoftware,
    FinTech,
    BlockchainDevelopment,
    CooperationModel,
    CooperationModelText,
    ProductDevelopment,
    StaffAugmentation,
    ClientValueUs,
    ClientValueUsText,
    ClientValueUsPage,
    ReadmoreAboutUs,
    WeVuega,
    StartWorkingWithUs,
    StartWorkingWithUsText,
    StartWithUsTimeline,
    ContactUs,

  },
  setup(){
    useHead({
  title: 'Home - Vuega',
  meta: [
    {
      name: 'description',
      content: 'Welcome to Vuega - We provide AI solutions, web development, and mobile app development services.',
    },
    {
      name: 'keywords',
      content: 'AI solutions, web development, mobile app development, Vuega',
    },
    {
      property: 'og:title',
      content: 'Home - Vuega',
    },
    {
      property: 'og:description',
      content: 'Explore Vuega\'s innovative services in AI, web, and mobile development.',
    },
    {
      property: 'og:type',
      content: 'website',
    },
    {
      property: 'og:url',
      content: 'https://vuega.net',
    },
    {
      property: 'og:sitename',
      content: 'Vuega',
    },
    {
      property: 'og:email',
      content: 'contact@vuega.net',
    },
    {
      property: 'og:phone_number',
      content: '+91-9489241506',
    },
    {
      property: ''
    }
  ],
});
  },
  mounted() {
    const sr = ScrollReveal({
      origin: 'bottom',
      distance: '100px',
      duration: 1500,
      delay: 400,
      reset: false,
      viewFactor: 0.1,
    });
    sr.reveal('.container');
    sr.reveal('.software-company', { interval: 300 });
    sr.reveal('.software-company > *', { interval: 300 });
  }
}



</script>

<style scoped>
.container,
.software-company {
  visibility: hidden; /* Ensure elements are initially hidden */
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translate3d(0, 300px, 0);
  }
  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

.container {
  height: 600px;
  max-width: 75%;
  background-color: white; /* Change to your desired background color */
  padding: 10px 15px; /* Padding around the container */
  border-radius: 6px; /* Optional: rounded corners */
  margin: 0 auto; /* Center horizontally */
  margin-top: 100px;
  animation: fadeInUp 1s ease-in-out forwards; /* Add animation */
}

.cn-1 {
  display: flex;
  justify-content: space-between; /* Pushes items to the edges */
  align-items: flex-start; /* Align items to the top */
}

.text-container {
  margin-top: 50px;
  display: flex;
  flex-direction: column; /* Stack children vertically */
}

.about-text {
  margin-top: 0; /* Adjust this value to move AboutText higher or lower */
}

.para-text {
  margin-top: 35px; /* Adjust the space between AboutText and Para */
}

.working-image {
  align-self: flex-end;
}
</style>
