<template>
  <div class="blue-container" @click="openForm">
    <font-awesome-icon :icon="['fas', 'comment-alt']" />
    <span>Let's Talk</span>
  </div>
</template>

<script>
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faCommentAlt } from '@fortawesome/free-solid-svg-icons';

// Add the FontAwesome icon to the library
library.add(faCommentAlt);

export default {
  name: 'LetsTalk',
  components: {
    FontAwesomeIcon,
  },
  methods: {
    openForm() {
      window.open('https://forms.gle/UwUgknFtMZmaoidC7', '_blank');
    }
  }
}
</script>

<style scoped>
.blue-container {
  font-family: "Kanit", sans-serif;
  font-weight: 400;
  font-style: normal;
  max-width: 100px;
  display: flex;
  align-items: center;
  background-color: #0435f9; /* Blue background color */
  padding: 10px 15px; /* Padding around the text */
  border-radius: 6px; /* Rounded corners */
  color: white; /* Text color inside the blue container */
  font-weight: bold;
  font-size: 16px; /* Smaller font size */
  transition: background-color 0.3s ease; /* Smooth transition for background color */
  cursor: pointer; /* Indicate that it's clickable */
}

.blue-container svg {
  margin-right: 10px; /* Space between icon and text */
  font-size: 18px; /* Adjust the icon size */
}

.blue-container:hover {
  background-color: #021a7a; /* Change background color on hover */
}
</style>
