<template>
    <div>
    <div class="soft-cmp">
        
        <p>Looking for a mobile development partner to assist you with setting your digital presence? Look no further because we have the skill set and experience you need to succeed in the mobile market. With our custom app development services, you will develop top-quality software, elevating your brand’s recognition. Don’t waste time creating downscale solutions. Build your software with a mobile software development company that knows the drill.</p>
        

    </div>

    
</div>
  </template>
  
  <script>
  export default {
    name: 'TopWebDesignText'
  }
  </script>
  
  <style scoped>
  .soft-cmp {
    align-content: flex-start;
    justify-content: start;
    text-align: start;
    background-color: white; /* Background color */
    padding: 20px 20px 20px 0; /* Padding: Top, Right, Bottom, Left */
    border-radius: 8px; /* Rounded corners */
    text-align: start; /* Text alignment */
    margin: 0px auto; /* Center the container and add top/bottom margin */
    max-width: 75%; /* Limit the max width */
    position: relative;
    max-height: 10px;
    
  }
  
  p {
    font-family: "Montserrat", sans-serif;
    line-height: 28px;
    padding: 23px 0 0;
    font-size: 16px !important;
    font-weight: 400 !important;
    margin-top: 0;/* Remove default margin to eliminate space between headings */
    
  }
  </style>
  