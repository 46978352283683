<template>
    <div class="big-container">
      <div class="row">
        <div class="left-container">
          <h3>Committed team</h3>
        </div>
        <div class="right-container">
          <p>We are passionate about our work, which drives us to strive for excellence in every product. Our team of custom app developers is dedicated to staying on top of the newest mobile development practices.</p>
        </div>
      </div>
      <div class="row">
        <div class="left-container">
          <h3>Agile development
          </h3>
        </div>
        <div class="right-container">
          <p>Adapting Scrum methodology to project management helps us squeeze the best out of our potential. Custom mobile application development broken down into repetitive.</p>
        </div>
      </div>
      <div class="row">
        <div class="left-container">
          <h3>Direct communication</h3>
        </div>
        <div class="right-container">
          <p>Our daily operations rely on effective two-way communication. To simplify this, we create a separate Slack channel for each project to act as a platform for team members to exchange information with our stakeholders.</p>
        </div>
      </div>
      <div class="row">
        <div class="left-container">
          <h3>Project expense control</h3>
        </div>
        <div class="right-container">
          <p>Vuega’s custom mobile application development services include budget management. We supervise the project’s expenditures for you, ensuring that each software layer is delivered cost-effectively.</p>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    name: "SplitContainer"
  };
  </script>
  
  <style scoped>
  .big-container {
    width: 75%;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    gap: 40px; /* Gap between rows */
  }
  
  .row {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .left-container,
  .right-container {
    width: 550px;
    height: 150px;
    border-radius: 10px;
  }
  
  .left-container {
    display: flex;
    align-items: center; /* Vertically center */
    background-color: white; /* Light gray background */
    padding-left: 20px; /* Add padding to the left for spacing */
  }
  
  .right-container {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: white; /* Slightly darker gray background */
  }
  
  h3 {
    font-family: "Montserrat", sans-serif;
    font-size: 32px !important;
    font-weight: 700 !important;
    line-height: 57px !important;
    font-style: normal;
    margin: 0; /* Remove default margin to eliminate space between headings */
    color: #333;
  }
  
  p {
    font-family: "Montserrat", sans-serif;
    font-weight: 400 !important;
    line-height: 25px !important;
    margin: 0; /* Remove default margin to eliminate space between headings */
    font-size: 16px;  
    text-align: center;
    padding: 0 10px; /* Padding for the text */
  }
  </style>
  