<template>
    <div class="main-container">
      <div class="left-part">
        <img src="@/assets/empowering.jpg" alt="Image" class="left-image">
      </div>
      <div class="right-part">
        <h1>Empowering Every Team Member</h1>
        <p>We believe in empowering our people to reach their full potential. By fostering a culture of collaboration, continuous learning, and respect, we ensure that every team member can contribute meaningfully and grow professionally.</p>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    name: 'EmpoweringContent',
    components: {}
  }
  </script>
  
  <style scoped>
  .main-container {
    margin-top: 50px;
    width: 100%;
    height: 600px;
    display: flex;
    box-sizing: border-box;
    background-color: #e2e6eb;
  }
  
  .left-part {
    width: 50%; /* Half of the container width */
    height: 100%;
    display: flex;
    justify-content: center; /* Center the image horizontally */
    align-items: center; /* Center the image vertically */
    padding: 20px; /* Optional: add some padding */
    box-sizing: border-box;
  }
  
  .left-image {
    width: 550px;
    height: 460px;
    border-radius: 15px; /* Rounded corners */
    object-fit: cover; /* Ensure the image covers the area */
  }
  
  .right-part {
    width: 50%; /* Half of the container width */
    height: 100%;
    padding: 20px; /* Add padding around the content */
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: center; /* Center content vertically */
  }
  
  h1 {
    font-family: "Montserrat", sans-serif;
    font-size: 36px;
    font-weight: bold;
    color: #333;
    margin: 0 0 20px; /* Margin around the heading */
  }
  
  p {
    font-family: "Montserrat", sans-serif;
    font-size: 18px;
    color: #555;
    line-height: 36px;
    margin: 0; /* Remove margin around the paragraph */
    padding-right: 200px;
  }
  </style>
  