<template>
    <div class="para-container">
      <p>We’re a software development company that loves finding solutions to
      complicated problems! We focus on the MSME industries, but we also
      implement solutions for fintech, healthcare, IoT, and startups. By creating
      fast and efficient mobile and web applications, we will help your business
      grow.</p>
      <div class="talk-btn">
        <LetsTalk/>
      </div>
    </div>
  </template>
  
  <script>
  import LetsTalk from './LetsTalk.vue';
  
  export default {
    name: 'ParaText',
    components: {
      LetsTalk,
    }
  }
  </script>
  
  <style scoped>
  p {
    font-family: "Zain", sans-serif;
    font-weight: 300;
    font-style: inherit;
    font-size: 23px;  
    margin: 0;
  }
  
  .para-container {
    padding-bottom: 40px; /* Add padding to the bottom of the container */
  }
  
  .talk-btn {
    margin-top: 20px; /* Move the button down */
  }
  </style>
  