<template>
    <div class="main-container">
      <div class="right-part">
        <video class="right-video" autoplay loop>
          <source src="@/assets/journey2.mp4" type="video/mp4" >
          Your browser does not support the video tag.
        </video>
      </div>
      <div class="left-part">
        <h1>Join Our Journey</h1>
        <p>Be a part of a dynamic team that's transforming the technology landscape. If you're passionate about innovation, excellence, and making a difference, Vuega is the place for you.</p>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    name: 'JoinJourney',
    components: {}
  }
  </script>
  
  <style scoped>
  .main-container {
    margin-top: 50px;
    width: 100%;
    height: 600px;
    display: flex;
    box-sizing: border-box;
    background-color: white;
    flex-direction: row-reverse; /* Reverses the order of the flex items */
  }
  
  .right-part {
    width: 50%; /* Half of the container width */
    height: 100%;
    display: flex;
    justify-content: center; /* Center the video horizontally */
    align-items: center; /* Center the video vertically */
    padding: 20px; /* Optional: add some padding */
    box-sizing: border-box;
  }
  
  .right-video {
    width: 550px;
    height: 460px;
    border-radius: 15px; /* Rounded corners */
    object-fit: cover; /* Ensure the video covers the area */
  }
  
  .left-part {
    width: 50%; /* Half of the container width */
    height: 100%;
    padding: 20px; /* Add padding around the content */
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: center; /* Center content vertically */
  }
  
  h1 {
    font-family: "Montserrat", sans-serif;
    font-size: 36px;
    font-weight: bold;
    color: #333;
    margin: 0 0 20px; /* Margin around the heading */
    padding-left: 200px;
  }
  
  p {
    font-family: "Montserrat", sans-serif;
    font-size: 18px;
    color: #555;
    line-height: 36px;
    margin: 0; /* Remove margin around the paragraph */
    padding-left: 200px; /* Optional: adjust padding to fit your design */
  }
  </style>
  