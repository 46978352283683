<template>
    <div class="timeline-container">
      <div class="timeline-point">
        <div class="content-container">
          <img src="@/assets/image1.svg" alt="Image 1" class="timeline-image" />
          <div class="timeline-content">
            <div class="point-number">01</div>
            <h1><br>Contact us</h1>
            <p class="timeline-text">Fill out the contact form, briefly describe your project or ask us a question.</p>
          </div>
        </div>
      </div>
      <div class="timeline-point">
        <div class="content-container">
          <div class="timeline-content">
            <div class="point-number">02</div>
           <h1><br>Discovery call</h1>
            <p class="timeline-text">Our team will arrange a meeting with you in order to clarify the project assumptions.</p>
            <img src="@/assets/image2.svg" alt="Image 2" class="timeline-image" />
        </div>
          
        </div>
      </div>
      <div class="timeline-point">
        <div class="content-container">
          <img src="@/assets/image3.svg" alt="Image 3" class="timeline-image" />
          <div class="timeline-content">
            <div class="point-number">03</div>
            <h1><br>Project estimation</h1>
            <p class="timeline-text">On the basis of the collected information, we will prepare an estimate of the costs and duration of the project.</p>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    name: 'StartWithUsTimeline',
  }
  </script>
  
  <style scoped>
  .timeline-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
    position: relative;
  }
  
  .timeline-point {
    display: flex;
    align-items: center;
    position: relative;
    flex: 1;
    text-align: center;
    margin: 0 10px;
  }
  
  .timeline-point:not(:last-child)::after {
    content: '';
    position: absolute;
    width: 100%;
    height: 2px;
    top: 50%;
    left: 100%;
    transform: translateY(-50%);
    z-index: -1;
  }
  
  .timeline-point:last-child::after {
    content: '';
    position: absolute;
    width: 50%;
    height: 2px;
    top: 50%;
    left: 100%;
    transform: translateY(-50%);
    z-index: -1;
  }
  
  .content-container {
    width: 75%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .point-number {
    color: #e0f0ff;
    font-family: Montserrat, helvetica neue, helvetica, arial, sans-serif;
    font-size: 50px;
    font-style: normal;
    font-weight: 800;
    line-height: 23px;
    top: 50px;
    text-align: center;
  }
  
  .timeline-content {
    background-color: #FFFFFF;
    border-radius: 8px;
    padding: 20px;
    width: 250px;
    margin-top: 20px;
  }
  
  .timeline-text {
    font-family: "Montserrat", sans-serif;
    line-height: 28px;
    padding: 23px 0 0;
    font-size: 16px !important;
    font-weight: 400 !important;
    margin-top: 0;/* Remove default margin to eliminate space between headings */
  }
  
  .timeline-image {
    height: fit-content;
    width: fit-content;
  }
  h1{
    font-family: "Montserrat", sans-serif;
    font-size: 24px !important;
    font-weight: 700 !important;
    line-height: 48px !important;
    margin: 0; /* Remove default margin to eliminate space between headings */
  
  }
  </style>
  