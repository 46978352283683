<template>
  <router-link to="/about-us" class="mobile-app-container">
    <div class="left-side">
      <!-- <h1>Mobile App Development</h1> -->
      <p>Vuega is a software development company that specializes in web and mobile applications. Our team has experience in software development working with industries like education, marketing, business, fintech, healthcare, IoT, and more. The quality of the products we deliver due to the quality of the team that we put at your disposal. We want you to work only with the best people on the market! That is why we invest in specialists with various skills – software developers, testers, UX/UI designers, and managers.</p>
      <div class="learn-more">
        <span>Learn More</span>
        <Icon icon="uil:arrow-right" width="20" color="#182eff" />
      </div>
    </div>
    <div class="right-side">
      <img src="@/assets/image.png" alt="Mobile App" />
    </div>
  </router-link>
</template>

<script>
import { Icon } from '@iconify/vue';

export default {
  name: 'MobileApp',
  components: {
    Icon
  }
}
</script>

<style scoped>
.mobile-app-container {
  display: flex;
  justify-content: space-between; /* Pushes items to the edges */
  align-items: center; /* Vertically center items */
  max-width: 75%;
  margin: 20px auto 0; /* Add top margin for spacing */
  padding: 20px;
  background-color: #f6fbfe; /* Background color */
  border-radius: 8px; /* Rounded corners */
  transition: all 0.3s ease; /* Smooth transition for hover effects */
  margin-top: 30px;
  height: 500px;
  text-decoration: none; /* Remove underline from the link */
  cursor: pointer; /* Change cursor on hover */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Default shadow effect */
}

.mobile-app-container:hover {
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2); /* Larger shadow on hover */
}

.mobile-app-container:hover .learn-more .iconify {
  transform: translateX(5px); /* Move icon slightly to the right on hover */
}

.left-side {
  display: flex;
  flex-direction: column;
  justify-content: center; /* Vertically center content */
  max-width: 50%; /* Limit the width of the left side */
  padding-left: 20px; /* Add space between the left side and the text */
}

.left-side h1 {
  font-family: "Montserrat", sans-serif;
  font-size: 36px;
  font-weight: 700;
  margin: 0 0 10px; /* Space below the heading */
  color: black; /* Default heading color */
}

.left-side p {
  font-family: "Montserrat", sans-serif;
  font-size: 16px !important;
  line-height: 28px !important;
  padding: 23px 0 0 0;
  color: black; /* Ensure text color is consistent */
}

.learn-more {
  display: flex;
  align-items: center;
  margin-top: 20px;
}

.learn-more span {
  font-family: "Montserrat", sans-serif;
  font-size: 16px;
  font-weight: bold;
  color: #182eff; /* Text color */
  margin-right: 10px; /* Space between text and icon */
}

.learn-more .iconify {
  font-size: 20px; /* Icon size */
  transition: transform 0.3s ease; /* Smooth transition for icon movement */
}

.tech-icons {
  display: flex;
  align-items: center;
  margin-top: 20px;
}

.tech-icons .iconify {
  font-size: 24px; /* Icon size */
  color: #B0B0B0; /* Faded grey color */
  margin-right: 15px; /* Space between icons */
}

.tech-icons .iconify:last-child {
  margin-right: 0; /* Remove margin for the last icon */
}

.right-side img {
  max-width: 100%; /* Ensure image scales responsively */
  width: 445px; /* Set the width to 445 pixels */
  height: 100%;
  border-radius: 8px; /* Optional: rounded corners for the image */
}
</style>
