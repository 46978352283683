import { createRouter, createWebHistory } from 'vue-router';
import AboutUs from './Pages/AboutUs.vue';
import AIDevelopment from './Pages/AIDevelopment.vue';
import IOTDevelopment from './Pages/IOTDevelopment.vue';
import MobileAppDevelopment from './Pages/MobileAppDevelopment.vue';
import WebDevelopmentPage from './Pages/WebDevelopmentPage.vue';
import YourCompany from './Pages/YourCompany.vue';
import CareersDevelopment from './Pages/CareersDevelopment.vue';
import OurProducts from './Pages/OurProducts.vue';

const routes = [
  { path: '/', component: YourCompany },
  { path: '/about-us', component: AboutUs },
  { path: '/ai-development', component: AIDevelopment },
  { path: '/iot-development', component: IOTDevelopment },
  { path: '/mobile-app-development', component: MobileAppDevelopment },
  { path: '/web-development', component: WebDevelopmentPage },
  { path: '/career', component: CareersDevelopment },
  { path: '/design-catalogue', component: OurProducts }
];

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { top: 0 };
    }
  }
});

export default router;
