<template>
    <div class="main-container">
      <div class="half-container">
        <div class="small-container">
          <div class="image-container">
            <img src="@/assets/Sujay_profile.png" alt="Person 4" />
          </div>
          <div class="text-container">
            <div class="vertical-line" style="background-color: #FF33A1;"></div> <!-- Vibrant Pink -->
            <h3>Sujay nithish N</h3>
            <h4>Marketing Lead</h4>
          </div>
        </div>
        <div class="small-container">
          <div class="image-container">
            <img src="@/assets/sumathi.png" alt="Person 5" />
          </div>
          <div class="text-container">
            <div class="vertical-line" style="background-color: #F1C40F;"></div> <!-- Vibrant Yellow -->
            <h3>Dr. Sumathi S</h3>
            <h4>Mentor</h4>
          </div>
        </div>
      </div>
      <div class="half-container">
        <div class="small-container">
          <div class="image-container">
            <img src="@/assets/subashini.png" alt="Person 9" />
          </div>
          <div class="text-container">
            <div class="vertical-line" style="background-color: #D35400;"></div> <!-- Vibrant Burnt Orange -->
            <h3>Subashini M</h3>
            <h4>CFO</h4>
          </div>
        </div>
        <div class="small-container">
          <div class="image-container">
            <img src="@/assets/vimal.jpg" alt="Person 10" />
          </div>
          <div class="text-container">
            <div class="vertical-line" style="background-color: #C0392B;"></div> <!-- Vibrant Red -->
            <h3>Dr. Vimal S</h3>
            <h4>Mentor</h4>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    name: 'AdditionalTeamMembers'
  }
  </script>
  
  <style scoped>
  .main-container {
    display: flex;
    width: 75%;
    margin: 0 auto;
    background-color: white;
    border-radius: 8px;
  }
  
  .half-container {
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 20px; /* Space between the small containers */
  }
  
  .small-container {
    display: flex;
    align-items: center; /* Center items vertically */
    background-color: white;
    border-radius: 8px;
  }
  
  .image-container {
    width: 150px; /* Adjusted width */
    height: 150px; /* Adjusted height */
    overflow: hidden;
    border-radius: 8px;
  }
  
  .image-container img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 8px;
  }
  
  .text-container {
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
    width: calc(100% - 170px); /* Adjusted width to account for image container */
    text-align: center;
  }
  
  .vertical-line {
    position: absolute;
    left: 100px; /* Adjusted left position to be close to heading */
    top: 25%;
    height: 60px;
    width: 3px;
    border-radius: 4px;
  }
  
  .text-container h3 {
    font-family: "Montserrat", sans-serif;
    font-size: 24px;
    font-weight: 700;
    margin: 0 0 10px 20px; /* Adjusted margin for alignment */
  }
  
  .text-container h4 {
    font-family: "Montserrat", sans-serif;
    font-size: 18px;
    font-weight: 500;
    margin: 0;
  }
  </style>
  