<template>
  <div id="app">
    <NavigationBar /> <!-- Add your Header component -->
    <LoadingScreen v-if="isLoading" />
    <router-view v-else /> <!-- Ensure the main content renders when loading is complete -->
    <Footer /> <!-- Add your Footer component -->
  </div>
</template>

<script>
import LoadingScreen from './components/LoadingScreen.vue';
import NavigationBar from './components/NavigationBar.vue'; // Import your Header component
import Footer from './components/FooterComponent.vue'; // Import your Footer component

export default {
  name: 'App',
  components: {
    LoadingScreen,
    NavigationBar,
    Footer
  },

  data() {
    return {
      isLoading: false
    };
  },
  mounted() {
    // Simulate an asynchronous operation (e.g., API call)
    setTimeout(() => {
      this.isLoading = false;
    }, 1500); // Adjust the delay to match your loading time
  }
}


</script>

<style>
/* Global styles */
html, body {
  scroll-behavior: smooth; /* Smooth scrolling */
  margin: 0; /* Remove default margin */
  padding: 0; /* Remove default padding */
  height: 100%; /* Full height */
}

#app {
  min-height: 100vh; /* Ensure app takes full viewport height */
  display: flex;
  flex-direction: column; /* Arrange header, content, and footer vertically */
}

footer {
  margin-top: auto; /* Push footer to the bottom */
}
</style>
