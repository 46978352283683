<template>
    <div class="red-container">
      <div class="grid-container">
        <div class="grid-item first-item">
          <div class="text-content">
            <h1>Free Estimation</h1>
            <p>Free project estimation and deep understanding of your needs before the project starts – this is the first saving you get working in cooperation with Vuega.</p>
          </div>
        </div>
  
        <div class="grid-item second-item">
          <img src="@/assets/Copy-of-IMG_1032-scaled.jpg" alt="Mobile App" />
        </div>
        
        <div class="grid-item text-item">
          <h1>On budget, on-time</h1>
          <p>Work done in accordance with specifications, within budget, and on time. Always predictable and stable cooperation.</p>
        </div>
  
        <div class="grid-item second-item">
          <img src="@/assets/1-2-1.jpg" alt="image" />
        </div>
  
        <div class="grid-item text-item">
          <h1>Project workshops</h1>
          <p>We are not waiting for a ready-made action plan, we use a proactive approach. We are here to help you in the creation of product strategy and in making the key decisions.</p>
        </div>
        
        <div class="grid-item text-item">
          <h1>Dedicated team</h1>
          <p>Each project has a dedicated team with a certified project manager and other experts with excellent technical skills in their field depending on the project requirements.</p>
        </div>
  
        <div class="grid-item text-item">
          <h1>Passionate people</h1>
          <p>Vuega employs many beautiful minds who have a lot of passion for programming. Nothing will stop them – they are at your call.</p>
        </div>
  
        <div class="grid-item second-item">
          <img src="@/assets/Foto_094-scaled.jpg" alt="image" />
        </div>
  
        <div class="grid-item text-item">
          <h1>Code ownership</h1>
          <p>We do not claim any ownership of the software we make. We do the job for you, and when completed, you will have full ownership of the code.</p>
        </div>
  
        <div class="grid-item second-item">
          <img src="@/assets/Copy-of-IMG_1477-scaled.jpg" alt="image" />
        </div>
  
        <div class="grid-item text-item">
          <h1>High-quality products</h1>
          <p>We’ll make you a fully professional app, written with modern technology, that has no bugs, and optimized performance, so you will be able to develop and use it for years.</p>
        </div>
  
        <div class="grid-item second-item">
          <img src="@/assets/Foto_076-scaled.jpg" alt="image" />
        </div>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    name: 'ClientValueUsPage',
  }
  </script>
  
  <style scoped>
  .red-container {
    display: grid;
    height: 1370px; /* Set height of the main container */
    width: 75%; /* Set width of the main container */
    background-color: white; /* Set background color to white */
    margin: 0 auto; /* Center horizontally */
    padding: 0; /* Optional: padding inside the container */
    border-radius: 8px; /* Optional: rounded corners */
    box-sizing: border-box; /* Include padding and border in the element's total width and height */
  }
  
  .grid-container {
    display: grid;
    grid-template-columns: repeat(3, 365px); /* 3 columns with fixed width */
    grid-template-rows: repeat(4, 283px); /* 4 rows with fixed height */
    column-gap: 60px; /* Space between containers */
    row-gap: 40px;
    height: 100%; /* Fill the height of the parent */
    box-sizing: border-box; /* Include padding and border in the element's total height */
  }
  
  .grid-item {
    background-color: white; /* Set background color for the items */
    border-radius: 8px; /* Rounded corners for the items */
    display: flex;
    align-items: center;
    justify-content: center;
    width: 400px; /* Set the width of the items */
    height: 300px; /* Set the height of the items */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Light shadow */
    overflow: hidden; /* Ensure that the border-radius is respected */
    transition: transform 0.3s ease, box-shadow 0.3s ease; /* Smooth transition for hover effects */
  }
  
  .grid-item:hover {
    transform: scale(1.02); /* Slightly enlarge the item on hover */
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.3); /* Darker shadow on hover */
  }
  
  .first-item, .text-item {
    display: flex;
    flex-direction: column; /* Arrange heading and paragraph vertically */
    justify-content: center; /* Center items vertically */
    padding: 20px; /* Optional: padding inside the item */
    box-sizing: border-box; /* Include padding in the total width and height */
  }
  
  .first-item h1, .text-item h1 {
    margin: 0 0 10px; /* Space below the heading */
  }
  
  .first-item p, .text-item p {
    margin: 0; /* Remove default margin */
  }
  
  .second-item {
    display: flex; /* Flexbox to handle the image */
    overflow: hidden; /* Ensure images don't overflow their containers */
  }
  
  .second-item img {
    width: 100%; /* Ensure the image fills the container width */
    height: 100%; /* Ensure the image fills the container height */
    object-fit: cover; /* Cover the container with the image */
    border-radius: 8px; /* Optional: rounded corners */
    filter: grayscale(100%); /* Make image grayscale by default */
    transition: filter 0.3s ease; /* Smooth transition for hover effect */
  }
  
  .second-item:hover img {
    filter: grayscale(0%); /* Remove grayscale on hover */
  }
  
  h1 {
    font-family: "Montserrat", sans-serif;
    font-size: 24px !important;
    font-weight: 700 !important;
    line-height: 30px !important;
  }
  
  p {
    font-family: "Montserrat", sans-serif;
    font-size: 16px !important;
    font-weight: 400 !important;
    line-height: 30px !important;
    margin: 0 auto;
  }
  
  .text-item {
    display: flex;
    flex-direction: column; /* Arrange heading and paragraph vertically */
    align-items: flex-start; /* Align items to the start */
  }
  
  .text-item h1, .text-item p {
    margin: 0 0 10px; /* Space below the heading and paragraph */
    text-align: left; /* Align text to the left */
  }
  </style>
  