<template>
    <div class="web-development-container">
      <div class="left-side">
        <img src="@/assets/hand-holding-creative-light-bulb-with-cog-inside-and-technology-background-innovation-business-idea-knowledge-planing-strategy-and-analysis-solution-development-concept-scaled.jpg" alt="Description" />
      </div>
      <div class="right-side">
        <h1>Product development</h1>
        <p>An agile team covering all the skills is needed for valuable custom Digital Product development. Hire experienced team of Web & Mobile Developers, Project Managers, Designers, Testers, and DevOps who understand how to solve your business challenges.</p>
        <div class="learn-more">
          <span>Learn More</span>
          <Icon icon="uil:arrow-right" width="20" color="#182eff" />
        </div>
        <div class="tech-icons">
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import { Icon } from '@iconify/vue';
  
  export default {
    name: 'ProductDevelopment',
    components: {
      Icon
    }
  }
  </script>
  
  <style scoped>
  .web-development-container {
    display: flex;
    justify-content: space-between; /* Pushes items to the edges */
    align-items: flex-start; /* Align items to the top */
    max-width: 75%;
    margin: 20px auto 0; /* Add top margin for spacing */
    padding: 20px;
    background-color: #ffffff; /* Background color */
    border-radius: 8px; /* Rounded corners */
    border: 1px solid #c3c3c3; /* Default border color */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Default shadow effect */
    transition: all 0.3s ease; /* Smooth transition for hover effects */
  }
  
  .web-development-container:hover {
    border: 1px solid #182eff; /* Outline color on hover */
  }
  
  .web-development-container:hover .right-side h1 {
    color: #182eff; /* Heading color on hover */
  }
  
  .web-development-container:hover .learn-more .iconify {
    transform: translateX(5px); /* Move icon slightly to the right on hover */
  }
  
  .left-side img {
    max-width: 100%; /* Ensure image scales responsively */
    width: 445px; /* Set the width to 445 pixels */
    height: 285px;
    border-radius: 8px; /* Optional: rounded corners for the image */
  }
  
  .right-side {
    margin-top: 20px;
    max-width: 50%; /* Limit the width of the right side */
  }
  
  .right-side h1 {
    font-family: "Montserrat", sans-serif;
    font-size: 36px;
    font-weight: 700;
    margin: 0 0 10px; /* Space below the heading */
    color: black; /* Default heading color */
  }
  
  .right-side p {
    font-family: "Montserrat", sans-serif;
    font-size: 16px !important;
    line-height: 28px !important;
    padding: 23px 0 0 0;
    color: black; /* Ensure text color is consistent */
  }
  
  .learn-more {
    display: flex;
    align-items: center;
    margin-top: 20px;
    cursor: pointer;
  }
  
  .learn-more span {
    font-family: "Montserrat", sans-serif;
    font-size: 16px;
    font-weight: bold;
    color: #182eff; /* Text color */
    margin-right: 10px; /* Space between text and icon */
  }
  
  .learn-more .iconify {
    font-size: 20px; /* Icon size */
    transition: transform 0.3s ease; /* Smooth transition for icon movement */
  }
  
  .tech-icons {
    display: flex;
    align-items: center;
    margin-top: 20px;
  }
  
  .tech-icons .iconify {
    font-size: 24px; /* Icon size */
    color: #B0B0B0; /* Faded grey color */
    margin-right: 15px; /* Space between icons */
  }
  
  .tech-icons .iconify:last-child {
    margin-right: 0; /* Remove margin for the last icon */
  }
  </style>
  