<template>
    <div class="soft-cmp">
      <p>Depending on your needs, we offer different models of cooperation. If you do not have an in-house IT department, we can recommend you hire a Product Development team, which is a great way to save time and money and give you peace of mind resulting from the awareness that experts do your product development. For companies that have their own IT department, we recommend Staff Augmentation which fulfills a lack of knowledge of a specific technology, experience in a given industry or wanting to speed up development work and increase efficiency.

</p>
    </div>
  </template>
  
  <script>
  export default {
    name: 'CooperationModelText',
  }
  </script>
  
  <style scoped>
  .soft-cmp {
    align-content: flex-start;
    justify-content: start;
    text-align: start;
    background-color: white; /* Background color */
    padding: 20px 20px 20px 0; /* Padding: Top, Right, Bottom, Left */
    border-radius: 8px; /* Rounded corners */
    text-align: start; /* Text alignment */
    margin: 20px auto; /* Center the container and add top/bottom margin */
    max-width: 75%; /* Limit the max width */
    position: relative;
    max-height: 200px;
  }
  
  p {
    font-family: "Montserrat", sans-serif;
    line-height: 28px;
    padding: 23px 0 0;
    font-size: 16px !important;
    font-weight: 400 !important;
    margin-top: 0;/* Remove default margin to eliminate space between headings */
    
  }
  </style>
    