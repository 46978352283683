<template>
    <div>
    <div class="soft-cmp">
        
        <p>If your plans include a web development project, you have come to the right place. As a web development agency with a solid background in delivering innovative web solutions, we provide professional web services to companies that dream big.</p>
        <p>Our skilled web developers are eager to produce a top-quality product tailored to the specific needs of your business. The whole team stays in the loop with the current trends, ensuring your new web app outshines the competition and brings genuine value to your clients.</p>

    </div>

    
</div>
  </template>
  
  <script>
  export default {
    name: 'TopWebDesignText'
  }
  </script>
  
  <style scoped>
  .soft-cmp {
    align-content: flex-start;
    justify-content: start;
    text-align: start;
    background-color: white; /* Background color */
    padding: 20px 20px 20px 0; /* Padding: Top, Right, Bottom, Left */
    border-radius: 8px; /* Rounded corners */
    text-align: start; /* Text alignment */
    margin: 20px auto; /* Center the container and add top/bottom margin */
    max-width: 75%; /* Limit the max width */
    position: relative;
    max-height: 10px;
  }
  
  p {
    font-family: "Montserrat", sans-serif;
    line-height: 28px;
    padding: 23px 0 0;
    font-size: 16px !important;
    font-weight: 400 !important;
    margin-top: 0;/* Remove default margin to eliminate space between headings */
    
  }
  </style>
  