<template>
    <div class="soft-cmp">
      <p>Vuega as a software development company provides services ranging from product design to delivery and launch. We are ready to design your product and implement web and mobile apps that accomplish your business goals!</p>
    </div>
  </template>
  
  <script>
  export default {
    name: 'SoftwareCompany'
  }
  </script>
  
  <style scoped>
  .soft-cmp {
    align-content: flex-start;
    justify-content: start;
    text-align: start;
    background-color: white; /* Background color */
    padding: 20px 20px 20px 0; /* Padding: Top, Right, Bottom, Left */
    border-radius: 8px; /* Rounded corners */
    text-align: start; /* Text alignment */
    margin: 20px auto; /* Center the container and add top/bottom margin */
    max-width: 75%; /* Limit the max width */
    position: relative;
    max-height: 200px;
  }
  
  p {
    font-family: "Montserrat", sans-serif;
    line-height: 28px;
    padding: 23px 0 0;
    font-size: 16px !important;
    font-weight: 400 !important;
    margin-top: 0;/* Remove default margin to eliminate space between headings */
    
  }
  </style>
  